import React, {useEffect, useState} from 'react';
import {Modal} from '@fluentui/react/lib/Modal';
import {Stack} from '@fluentui/react/lib/Stack';
import {DefaultButton, IconButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {Separator} from '@fluentui/react/lib/Separator';
import {DialogFooter} from '@fluentui/react/lib/Dialog';
import {FontWeights, getTheme, mergeStyleSets} from '@fluentui/react/lib/Styling';
import {IIconProps} from '@fluentui/react/lib/Icon';
import {TextField} from '@fluentui/react/lib/TextField';
import {Label} from '@fluentui/react/lib/Label';
import '../../Custom.css'
import Helper from '../../Helpers/Helper';
import {Text} from '@fluentui/react/lib/Text';
import {IMessageBarStyles, MessageBar, MessageBarType, Spinner, SpinnerSize, StackItem} from '@fluentui/react';
import {useConstCallback} from '@uifabric/react-hooks';
import {ILayout} from './CustomTemplate';
import {useTranslation} from "react-i18next";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {useBranding} from "../../hooks/useBranding";
import ListService, {IClonListMasterRequest, IClonListMasterResponse} from "../../services/List";
import HttpRequest from "../../services/HttpRequest";

export interface ITemplates {
    Id: number;
    AccountId: number;
    TemplateId: string;
    DocumentOnlyAudienceId: string;
    AllowEsign: boolean;
    AllowCheckInOut: boolean;
    eSignProvider: any;
    eSignUserId: any;
    eSignPassword: string;
    eSignIntegrationKey: any,
    eSignURL: string;
    PropertyBag: any,
    FileName: string;
    Category: string;
    Description: string;
    DisplayName: string;
    DisplayNameToolTip: string;
    TemplateContent: string
}
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
function CloneTemplate(props: any) {
    const { branding } = useBranding()
    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessageError, setshowMessageError] = useState(false)
    const [Successmessage, setSuccessmessage] = useState("");
    const [requiredEmail, setrequiredEmail] = useState(false)
    const [message, setMessage] = useState("");
    const [Useremail, setUseremail] = useState("")
    const [DisableUpdateUser, setDisableUpdateUser] = useState(true)
    const [templateId, setTemplateId] = useState("")
    const [newtemplateId, setnewtemplateId] = useState("")
    const [layoutOriginal, setlayoutOriginal] = useState<ILayout[]>()
    const [pageCount, setpageCount] = useState("")
    const [applying, setApplying] = useState(false);
    const [isCloning, setIsCloning] = useState(false)
    const { t } = useTranslation(['common', 'clone']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header:
            {
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },

        body: {
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

        User: {
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            height: '10vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '50vw'
        },
    });


    const emptyTemplate: ITemplates = {

        AccountId: 0,
        AllowCheckInOut: false,
        AllowEsign: false,
        Category: "",
        Description: "",
        DisplayName: "",
        DisplayNameToolTip: "",
        DocumentOnlyAudienceId: "",
        FileName: "",
        Id: 0,
        PropertyBag: "",
        TemplateContent: "",
        TemplateId: "",
        eSignIntegrationKey: "",
        eSignPassword: "",
        eSignProvider: "",
        eSignURL: "",
        eSignUserId: ""
    };
    const [dataTemplate, setdataTemplate] = useState(emptyTemplate)
    useEffect(() => {
        if (props.templateId !== undefined && props.templateId !== "") {
            setTemplateId(props.templateId)
            getTemplateId(props.templateId)
        }
    }, [props.showCloneTemplate]);

    const getTemplateId = async (templateId: string) => {
        await Helper.getDocumentOnlyTemplateId(templateId, true).then(data => {
            Init(data)
        })
    }

    const Init = useConstCallback((data: any) => {

        if (data !== undefined && data !== null) {
            setdataTemplate(data)
            sessionStorage.setItem("layout", data.FormDesignObject.Layout)
            if (data !== undefined && data.FormDesignObject) {
                setpageCount(data.FormDesignObject.Pages)
                setlayoutOriginal(JSON.parse(data.FormDesignObject.Layout) as ILayout[])
            }
        }
    })

    const getErrorMessage = (value: string): string => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredEmail(false)
                return t('text.emailNoValid', { ns: 'common' });
            }
            setrequiredEmail(true)
        }
        return ""
    };
    const checkRequiredfields = () => {
        if (Useremail !== "") {
            setDisableUpdateUser(false)
        } else {
            setDisableUpdateUser(true)
        }
    }
    const hideModal = () => {
        setMessage("")
        setShowSuccess(false)
        setApplying(false)
        setUseremail("")
        setshowMessageError(false)
        setIsCloning(false)
        props.hideCloneTemplate()
    }
    const cloneTemplate = () => {
        setrequiredEmail(false)
        setIsCloning(true)
        goToClone()
    }

    const goToClone = async () => {

        await Helper.getCloneTemplate(templateId, Useremail).then(data => {
            if (data.Success == "true") {
                setShowSuccess(true)
                setshowMessageError(false)
                setnewtemplateId(data.Result)
                setSuccessmessage(t('message.success', { ns: 'clone' }))
                goToCloneList(data.Result)
            }
            else {
                setshowMessageError(true)
                setMessage(t('message.error', { ns: 'clone', Error: data.ExceptionMessage }))
                setTimeout(function () {
                    setshowMessageError(false)
                    hideModal()
                }, 6000);
            }
        })
    }

    const goToCloneList = async (newTemplateId: string) => {

        const request: IClonListMasterRequest = {
            IsExample: false,
            NewtemplateId: newTemplateId,
            templateId: templateId,
            userEmail: Useremail
        }

        await ListService.ClonListMaster(request)
            .then((data) => {
                saveLayoutOnStateManagement(newTemplateId, data)
            })
            .catch()
            .finally()
    }


    async function saveLayoutOnStateManagement(newTemplateId: string, LayoutResponse: IClonListMasterResponse) {
        const site = await Helper.getSite()!
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        const templateLayout = {
            TemplateId: newTemplateId,
            Owner: Useremail,
            Layout: LayoutResponse.Layout,
            Pages: LayoutResponse.Pages,
            FormRules: LayoutResponse.FormRules,
            Released: false
        };

        const request = {
            TemplateId: newTemplateId,
            User: "",
            Template: templateLayout
        }

        let {data}: any = await HttpRequest.postWithSession('statemanagement', request)
              
        if (data.Status == "Success") {
            setMessage(t('message.successLayout', { ns: 'clone' }))
            setShowSuccess(true)
            setApplying(false)
            setTimeout(function () {
                hideModal();

            }, 1000);
        }
        else {
            setApplying(false)
            setMessage(t('message.errorLayout', { ns: 'clone' }))
            setshowMessageError(true)
            setTimeout(function () {
                setShowSuccess(false)
                hideModal()
            }, 2000);
        }
        props.reloadApp(site)
    }

    return (
        <Modal
            isOpen={props.showCloneTemplate}
            isBlocking={true}>
            <Stack className={contentStyles.header} horizontal>

                <StackItem grow={1}>
                    <Text id={props.modalTitle} variant={"xLarge"}>{t('title.modal', { ns: 'clone', Name: props.templateName, interpolation: { 'escapeValue': false } })}</Text>
                </StackItem>
                <StackItem>
                    <HelpComponentRelative
                        headline={t('title.help', { ns: 'clone' })}
                        text={t('text.help', { ns: 'clone' })}
                        right="50px"
                        top="15px"
                        location="applicationlibrary"
                    />
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                        onClick={hideModal}
                        title={t('Common.Button.Close', { ns: 'common' })}
                    />
                </StackItem>

            </Stack>
            <Separator />
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.bodyDelete}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{Successmessage}</MessageBar> : null}
                                            {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                                            <Label required>{t('label.requiredEmail', { ns: 'clone' })}</Label>
                                            <TextField onGetErrorMessage={getErrorMessage} autoFocus={true} id="cloneDescription" title={t('title.inputEmail', { ns: 'clone' })} maxLength={1000} onChange={(_, v) => { setUseremail(v!); checkRequiredfields() }} value={Useremail} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isCloning ?
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={cloneTemplate} disabled={!requiredEmail} id="cloneButton" text={t('text.buttonClone', { ns: 'clone' })} title={t('title.buttonClone', { ns: 'clone' })} />
                                <DefaultButton onClick={hideModal} text={t('Common.Button.Cancel', { ns: 'common' })} title={t('Common.Button.Cancel', { ns: 'common' })} />
                            </DialogFooter>
                        </div>
                    </div>
                    :
                    <Spinner size={SpinnerSize.medium} label={t('text.spinnerCloning', { ns: 'common' })} ariaLive="assertive" />}
                <br />
            </div>
        </Modal>
    );
}

export default CloneTemplate