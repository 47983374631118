import {
    ActionButton,
    DefaultButton,
    FontSizes,
    FontWeights,
    getTheme,
    IButtonStyles,
    Icon,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IIconProps,
    Label,
    Link,
    mergeStyleSets,
    Pivot,
    PivotItem,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import React, {useEffect, useState} from 'react'
import {ILayout} from '../CustomTemplate';
import CustomDialog from '../../../share/CustomDialog';
import ExpressionBuilder from '../ExpressionBuilder';
import {useConstCallback} from '@fluentui/react-hooks';
import WizardService, {OperationsManualTagType} from '../../../services/wizard';
import StatusMessage, {StatusEnum} from '../../../share/StatusMessage';
import {FormAssemblyActions, FormRuleEditMode, IFormAssemblyRule} from '../../../interfaces/IFormAssembly';
import {useModal} from '../../../hooks/useModal';
import {Sizes} from '../../../share/CustomModal';
import {IStatusMessage} from '../../../interfaces/IApp';
import {useFormAssembly} from './hooks/useFormAssembly';
import {useBranding} from '../../../hooks/useBranding';

const theme = getTheme();

const saveIcon: IIconProps = {iconName: "Save"};
const previewIcon: IIconProps = {iconName: "PreviewLink"};
const pageIcon: IIconProps = {iconName: "PageAdd"};
const pageRIcon: IIconProps = {iconName: "PageRemove"};
const ExpIcon: IIconProps = {iconName: "EngineeringGroup"};
const PlusIcon: IIconProps = {iconName: "AppIconDefaultAdd"};
const Source: IIconProps = {iconName: "Source"};

type ToolBoxProps = {
    templateId: any
    openPreview: any
    templateName: any
    siteSelected: any
    items: ILayout[];
    targetButtonT: any
    onAddPage: any
    setItemDragged: Function;
    onTakeItem: any;
    unexpectedError: boolean
    saveLayoutOnStateManagement: any
    onRemovePage: any
    pageCount: any
    pageSelected: any
    getDefaultLayout: any
    setToolBoxItems: React.Dispatch<React.SetStateAction<ILayout[]>>
    saveToolboxItems: any
    setOperationsManualTags: React.Dispatch<React.SetStateAction<OperationsManualTagType[]>>
    operationsManualTags: OperationsManualTagType[]
    layouts: ILayout[],
    handleFormAssemblySteps: () => void
};

export default function ToolBox({
                                    templateId,
                                    templateName,
                                    siteSelected,
                                    items,
                                    openPreview,
                                    targetButtonT,
                                    onAddPage,
                                    setItemDragged,
                                    onTakeItem,
                                    unexpectedError,
                                    saveLayoutOnStateManagement,
                                    onRemovePage,
                                    pageCount,
                                    pageSelected,
                                    getDefaultLayout,
                                    setToolBoxItems,
                                    saveToolboxItems,
                                    setOperationsManualTags,
                                    operationsManualTags,
                                    layouts,
                                    handleFormAssemblySteps
                                }: ToolBoxProps) {

    const {handleFormAssembly, onEditModeChange, handleSwap, formRules, handleRemove, handleDisable, editMode, setEditMode, resetEditMode} = useFormAssembly()
    const [modalExpBuilder, setModalExpBuilder] = useState(false);

    const [manualTagName, setManualTagName] = useState("");
    const [isOpenManualTag, setIsOpenManualTag] = useState(false);
    const [manualErrorMessage, setManualErrorMessage] = useState("");
    const [ruleIndex, setRuleIndex] = useState(-1)
    const [ruleItem, setRuleItem] = useState<IFormAssemblyRule | undefined>(undefined)
    const [showContentControlsTab, setShowContentControlsTab] = useState(true)
    const [showRemoveRuleConfirmation, setShowRemoveRuleConfirmation] = useState(false)
    
    const toggleExpBuilder = useConstCallback((open: boolean) => {
        if (!open) {
            // clearFields()
        }

        setModalExpBuilder(open);
    });
    
    const onChangeManualTag = (event: any) => {
        setManualTagName(event.target.value);
    };

    const openManualTag = () => {
        setIsOpenManualTag(true);
        setManualErrorMessage("");
        setManualTagName("");
    };

    const addTag = (tagname: string) => {
        const tempControl: ILayout = getDefaultLayout(tagname, tagname, false);
        setManualErrorMessage("");
        setToolBoxItems((items) => {
            const toolboxItems = [
                ...items,
                {...tempControl, manualTag: true},
            ];
            saveToolboxItems(toolboxItems);
            return toolboxItems;
        });

        setOperationsManualTags((prevs) => {
            return [...prevs, {id: manualTagName, operation: "add"}];
        });
    }

    const deleteManualTag = (tagname: string) => {
        setToolBoxItems((items) => {
            const filtered = items.filter((i) => i.Id !== tagname);
            saveToolboxItems([...filtered]);
            setOperationsManualTags((prevs) => {
                const isInOperation = prevs.find((o) => o.id === tagname);
                if (isInOperation) return prevs.filter((i) => i.id !== tagname);
                return [...prevs, {id: tagname, operation: "delete"}];
            });
            return [...filtered];
        });
    };


    /**
     * Function handleAddCondition is used to handle the action when adding a condition.
     *
     * @returns {void} This function does not return any value.
     */
    const handleAddCondition = (): void => {
        setRuleItem(undefined)
        setRuleIndex(-1)
        resetEditMode()
        handleFormAssemblySteps()
    }

    
    /**
     * Handles the removal of a rule.
     *
     * @param item
     * @param {number} index - The index of the condition to be removed.
     * @returns {void}
     */
    const handleRemoveCondition = (item: IFormAssemblyRule, index: number): void => {
        setRuleItem(item)
        setRuleIndex(index)
        setShowRemoveRuleConfirmation(true)
    }


    /**
     * Removes a rule from a specified index.
     *
     */
    const RemoveCondition = () => {
        resetEditMode()
        handleRemove(ruleIndex)
        setShowRemoveRuleConfirmation(false)
    }


    /**
     * Generates a contextual menu for a given menu index and form assembly rule.
     *
     * @param {number} index - The index of the menu item.
     * @param {IFormAssemblyRule} item - The form assembly rule.
     * @returns {IContextualMenuItem[]} - The generated contextual menu.
     */

    const menuItems = (index: number, item: IFormAssemblyRule): IContextualMenuItem[] => {
        const menu:  IContextualMenuItem[] = []


        menu.push({
            key: "edit",
            text: "Edit",
            title: "Edit",
            onClick: () => {
                setRuleItem(item)
                setRuleIndex(index)
                onEditModeChange({
                    type: "RULES_CONDITION",
                    contentControls: [],
                    index: index,
                    rule: item,
                    rules: formRules,
                    showBar: false
                })
                handleFormAssemblySteps()
            }
        })

        if(index !== 0) {
            menu.push({
                key: "up",
                text: "Up",
                title: "Up",
                disabled: index === 0,
                onClick: () => {
                    resetEditMode()
                    handleSwap(index, index - 1)
                }
            })
        }

        if(index < (items.length ?? 0) - 1) {
            menu.push({
                key: "down",
                text: "Down",
                title: "Down",
                disabled: index >= (items.length ?? 0) - 1,
                onClick: () => {
                    resetEditMode()
                    handleSwap(index, index + 1)
                }
            })
        }

        if(item.disabled !== undefined &&  item.disabled) {
            menu.push(
                {
                    key: 'enable',
                    text: "Enable",
                    title: "Enable",
                    onClick: () => {
                        handleDisable(index, false)
                    },
                }
            )
        }
        else {
            menu.push(
                {
                    key: 'disable',
                    text: "Disable",
                    title: "Disable",
                    onClick: () => {
                        handleDisable(index, true)
                    },
                }
            )
        }

        menu.push({
            key: "delete",
            text: "Delete",
            title: "Delete",
            onClick: () => {
                handleRemoveCondition(item, index)
            },
        })



        return menu
    }
    

    /**
     * Renders a single form rule item.
     *
     * @param {IFormAssemblyRule} item - The form assembly rule to be rendered.
     * @param {number} index - The index of the current item in the list.
     * @param {number} totalItems - The total number of items in the list.
     * @param {(item: IFormAssemblyRule, index: number) => void} handleRemoveCondition - Function to handle removing a condition from the list.
     * @param {(option: FormAssemblyActions, rule: IFormAssemblyRule) => IStatusMessage} handleFormAssembly - Function to handle form assembly actions.
     * @returns {JSX Element} - The rendered form rule item.
     */
    function FormRuleItem<T>({
                                 item,
                                 index,
                                 items,
                                 handleRemoveCondition,
                             }: FormRuleItemProps): JSX.Element {

        const {branding} = useBranding()

        return (
            <>
                <Stack id={item.ruleName}
                       styles={{
                           root: {
                               marginBottom: 8,
                               boxShadow: editMode.rule !== undefined && editMode.rule.ruleName === item.ruleName ? branding.theme.effects.elevation4 : 'none',
                               border: `solid 1px ${item.disabled !== undefined && item.disabled ? branding.theme.palette.neutralTertiary : editMode.rule !== undefined && editMode.rule.ruleName === item.ruleName ? branding.theme.palette.themeDarker : branding.theme.palette.themeDark}`
                           }
                       }}>

                    <Stack horizontal styles={{
                        root: {
                            cursor: 'pointer',
                            backgroundColor: item.disabled !== undefined && item.disabled ? editMode.rule !== undefined && editMode.rule.ruleName === item.ruleName ?  branding.theme.palette.neutralSecondaryAlt : branding.theme.palette.neutralTertiary  : editMode.rule !== undefined && editMode.rule.ruleName === item.ruleName ? branding.theme.palette.themeDarker : branding.theme.palette.themeDark,
                            alignItems: "center",
                            /*selectors: {
                                ':hover': {
                                    backgroundColor: item.disabled !== undefined && item.disabled ? branding.theme.palette.neutralSecondaryAlt : branding.theme.palette.themeDarker,
                                }                               
                            }*/
                        }
                        
                    }}
                           onClick={() => {
                               onEditModeChange({
                                   type: "RULES_CONDITION",
                                   contentControls: [],
                                   index: index,
                                   rule: item,
                                   rules: items,
                                   showBar: true
                               },)
                           }}
                    
                    >
                        <Stack grow={true} tokens={{padding: 4}} styles={{
                            root: {
                                overflow: 'hidden',
                            }
                        }}>
                            <Text variant={"medium"} title={item.ruleName}
                                  styles={{
                                      root: {
                                          fontWeight: FontWeights.semibold, whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          color: branding.theme.palette.white
                                      }
                                  }}>
                                {item.ruleName}
                            </Text>
                        </Stack>
                        <Stack styles={{root: {paddingTop: 4, paddingBottom: 4}}}>

                            <IconButton
                                menuIconProps={{
                                    iconName: "MoreVertical",
                                    style: {fontSize: 16},
                                }}
                                role="button"
                                title="Show actions"
                                data={item}
                                aria-haspopup
                                aria-label="Show actions"
                                styles={iconTagButtonStyles}
                                menuProps={{items: menuItems(index, item)}}
                            />
                        </Stack>

                    </Stack>

                    <Stack tokens={{padding: 4}}>
                        <Link
                            underlined={false}
                            style={{textDecoration: 'none'}}
                            styles={{
                                
                                root: {
                            
                                    color: item.disabled !== undefined && item.disabled ? branding.theme.palette.neutralTertiary : branding.theme.palette.black,
                                    selectors: {
                                        ':hover': {
                                            color: item.disabled !== undefined && item.disabled ? branding.theme.palette.neutralTertiary : branding.theme.palette.black,
                                        },
                                        ':active': {
                                            color: item.disabled !== undefined && item.disabled ? branding.theme.palette.neutralTertiary : branding.theme.palette.black,
                                        }
                                    }
                                },
                            }}

                            onClick={() => {
                                onEditModeChange({
                                    type: "RULES_CONDITION",
                                    contentControls: [],
                                    index: index,
                                    rule: item,
                                    rules: items,
                                    showBar: true
                                },)
                            }}>
                            <strong>Show</strong> {item.conditions.length} expressions, {item.actions.length} Actions.
                        </Link>
                    </Stack>
                </Stack>

            </>
        )
    }


    const saveManualTag = () => {
        const _manualTagName = manualTagName.trim()
        const isInOperations = operationsManualTags.find(
            (item) => item.id === _manualTagName && item.operation === "delete"
        );
        const isInLayouts = layouts.find(
            (item) => item.Id.toLowerCase() === _manualTagName.toLowerCase()
        );
        if (isInOperations && !isInLayouts) {
            addTag(_manualTagName);
            setOperationsManualTags((prevs) => {
                return prevs.filter(
                    (i) => i.id.toLowerCase() !== _manualTagName.toLowerCase()
                );
            });
            setIsOpenManualTag(false);
            return;
        }

        if (!isInLayouts) {
            WizardService.validateContentControl({
                TemplateId: Number(templateId),
                TagNames: [_manualTagName],
            }).then(({data}: any) => {
                if (data.Success) {
                    addTag(_manualTagName);
                    setIsOpenManualTag(false);
                } else {
                    setManualErrorMessage(data.ExceptionMessage);
                }
            });
        } else {
            setManualErrorMessage(
                `Content control(s) '${_manualTagName}' already exists for this template`
            );
        }
    }

    return (
        <>

            {!unexpectedError ? (
                <>
                    <Stack grow={true} styles={{root: {width: 280, height: '100%', padding: '0px 4px'}}}>

                        <Stack horizontal>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnSaveWizard"
                                    styles={iconBarButtonStyles}
                                    iconProps={saveIcon}
                                    ariaLabel="Save"
                                    onClick={saveLayoutOnStateManagement}
                                    title="Save form wizard layout and configuration."
                                />
                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnPreviewWizard"
                                    styles={iconBarButtonStyles}
                                    iconProps={previewIcon}
                                    ariaLabel="Preview"
                                    onClick={openPreview}
                                    title="Preview form wizard layout."
                                />
                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnNewPageWizard"
                                    styles={iconBarButtonStyles}
                                    iconProps={pageIcon}
                                    ariaLabel="New Page"
                                    onClick={onAddPage}
                                    title="Select additional pages to spread or organize controls across form wizard. "
                                />
                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnRemovePageWizard"
                                    styles={iconBarButtonStyles}
                                    iconProps={pageRIcon}
                                    ariaLabel="Remove Page"
                                    onClick={onRemovePage}
                                    title="Remove page from web form wizard."
                                    disabled={pageCount === 1 || pageSelected === 1 ? true : false}
                                />
                            </Stack>
                        </Stack>

                        <Stack horizontal>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnExpressionBuilder"
                                    styles={iconBarButtonStyles}
                                    iconProps={ExpIcon}
                                    ariaLabel="Expression Builder"
                                    onClick={() => {
                                        toggleExpBuilder(true);
                                    }}
                                    title="Open expression builder, performs basic math, date and string concatenation operations."
                                />

                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <IconButton
                                    id="btnManualTag"
                                    styles={iconBarButtonStyles}
                                    iconProps={PlusIcon}
                                    ariaLabel="Manual Tag"
                                    onClick={openManualTag}
                                    title="Add manual tag."
                                />
                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <Icon styles={{root: {width: 32}}}></Icon>
                            </Stack>
                            <Stack grow={true} styles={{root: {alignItems: 'center'}}}>
                                <Icon styles={{root: {width: 32}}}></Icon>
                            </Stack>
                        </Stack>

                        <Stack>
                            <Pivot styles={{count: {color: 'red', fontSize: FontSizes.small}}}
                                   onLinkClick={(item) => {
                                       setShowContentControlsTab(item!.props.itemKey! === 'contentControls')
                                   }}>
                                <PivotItem headerText="Content Controls" itemKey="contentControls" alwaysRender={true}
                                ></PivotItem>
                                <PivotItem headerText={"Rules"} itemCount={"beta"} alwaysRender={true}
                                           itemKey="formRules"> </PivotItem>
                            </Pivot>
                        </Stack>

                        {showContentControlsTab ?
                            <>

                                <Stack grow={true} className="scrollVisible"
                                       styles={{root: {overflow: 'auto', justifyContent: items === undefined || items.length === 0? 'center' : 'initial'}}}>
                                    {items === undefined || items.length === 0 ?
                                        <>
                                            <Stack>
                                                <Text variant={"small"} block styles={{root: {textAlign: 'center'}}}>No
                                                    items to show.</Text>
                                            </Stack>
                                        </>
                                        :
                                        <>
                                            {items.map((item: ILayout) => (
                                                <ToolBoxItem
                                                    key={item.Id}
                                                    item={item}
                                                    onTakeItem={onTakeItem}
                                                    setItemDragged={setItemDragged}
                                                    deleteTag={deleteManualTag}
                                                />
                                            ))}

                                        </>
                                    }
                                </Stack>
                            </>
                            :
                            <>
                                <Stack>
                                    <ActionButton iconProps={{iconName: 'Add'}} onClick={handleAddCondition}
                                                  styles={{root: {width: '100%', marginBottom: 8}}}>
                                        Add new rule
                                    </ActionButton>
                                </Stack>
                                <Stack grow={true} className="scrollVisible"
                                       styles={{root: {overflow: 'auto'}}}>
                                    {formRules.map((item, index) => (
                                        <FormRuleItem layouts={layouts}
                                                      key={item.ruleName}
                                                      item={item}
                                                      index={index}
                                                      handleRemoveCondition={handleRemoveCondition}
                                                      items={formRules}
                                                      onEditModeChange={onEditModeChange}
                                        />
                                    ))}
                                </Stack>
                            </>
                        }
                        <Separator/>
                        <Stack styles={{root: {marginBottom: 32}}}>
                            <ActionButton
                                id="btnAddIntegration"
                                onMouseDown={() => {
                                    setItemDragged("Integration");
                                }}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData("text/plain", "hello")
                                }
                                title="Select integration to place a button to execute an On Click integration."
                                text="Add Integration"
                                style={{maxHeight: 25}}
                                iconProps={{iconName: "WebAppBuilderFragment"}}
                            />
                            <ActionButton
                                id="btnAddLineBreak"
                                onMouseDown={() => {
                                    setItemDragged("LineBreak");
                                }}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData("text/plain", "hello")
                                }
                                title="Select line break to place a horizontal line across the form wizard."
                                text="Add Line Break"
                                style={{maxHeight: 25}}
                                iconProps={{iconName: "SecondaryNav"}}
                            />
                            <ActionButton
                                id="btnAddParagraph"
                                onMouseDown={() => {
                                    setItemDragged("Paragraph");
                                }}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData("text/plain", "hello")
                                }
                                title="Enter static text to provide instructions."
                                text="Add Paragraph"
                                style={{maxHeight: 25}}
                                iconProps={{iconName: "PlainText"}}
                            />
                            <ActionButton
                                id="btnAddSectionHeader"
                                onMouseDown={() => {
                                    setItemDragged("Section");
                                }}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData("text/plain", "hello")
                                }
                                title="Enter text to emphasis headings."
                                text="Add Section Header"
                                style={{maxHeight: 25}}
                                iconProps={{iconName: "InsertTextBox"}}
                            />
                        </Stack>
                    </Stack>
                </>
            ) : null}
            <ExpressionBuilder
                modalExpBuilder={modalExpBuilder}
                toggleExpBuilder={toggleExpBuilder}
                templateId={templateId}
                layouts={layouts}
                templateName={templateName}
                siteSelected={siteSelected}
            />
            <CustomDialog
                title={"Manual tag name."}
                titleId={"ManualTag"}
                isDisabled={manualTagName.trim().length === 0}
                close={() => {
                    setIsOpenManualTag(false);
                }}
                isOpen={isOpenManualTag}
                actionText={"Add"}
                confirm={saveManualTag}
            >
                <>
                    {manualErrorMessage !== "" ? (
                        <StatusMessage
                            setShowMessage={() => setManualErrorMessage("")}
                            status={StatusEnum.Error}
                        >
                            {manualErrorMessage}
                        </StatusMessage>
                    ) : (
                        <></>
                    )}
                    <TextField
                        label="Name"
                        required
                        maxLength={50}
                        onChange={onChangeManualTag}
                        placeholder="Manual tag name."
                        id="manualTagName"
                        title=""
                        value={manualTagName}
                    />
                </>
            </CustomDialog>

            {/*region Remove condition*/}
            <CustomDialog title={'Delete Confirmation'} titleId={`DeleteConfirmationConditional`}
                          actionText={'Yes'}
                          confirm={RemoveCondition}
                          close={() => {
                              setShowRemoveRuleConfirmation(false)
                          }}
                          isDisabled={false}
                          reversePrimary={true}
                          isOpen={showRemoveRuleConfirmation}
            >
                <>
                    <Text variant={"medium"}>
                        Do you want to delete the rule: <strong>{ruleItem?.ruleName}</strong>?
                    </Text>
                </>
            </CustomDialog>
            {/*endregion*/}
        </>
    )
}
type ToolBoxItemProps = {
    item: ILayout;
    setItemDragged: Function;
    onTakeItem: any;
    deleteTag: Function;
}

const menuItems = (item:ILayout, deleteTag:Function): IContextualMenuItem[] => {
    return [
        {
            key: "delete",
            text: "Delete",
            title: "Delete",
            onClick: () => {
                deleteTag(item.Id);
            },
        }
    ]
}

/**
 * Creates a ToolboxItem element.
 *
 * @param {ILayout} item - The layout item.
 * @param {Function} setItemDragged - The function to set the item as dragged.
 * @param {any} onTakeItem - The function called when the item is taken.
 * @param {Function} deleteTag - The function to delete the tag.
 * @returns {JSX.Element} - The rendered ToolboxItem element.
 * @constructor
 */
function ToolBoxItem({
                         item,
                         setItemDragged,
                         onTakeItem,
                         deleteTag,
                     }: ToolBoxItemProps): JSX.Element {
    const {branding} = useBranding()

    return (
        <>
            <Stack
                styles={{
                    root: {
                        cursor: 'pointer',
                        marginBottom: 8,
                        backgroundColor: item.manualTag?  branding.theme.palette.themeDark : branding.theme.palette.themeDarker
                    }
                }}
                onMouseDown={() => {
                    setItemDragged(item.Id);
                }}
                draggable={true}
                unselectable="on"
                onDragStart={(e) => 
                    e.dataTransfer.setData("text/plain", "hello")
                }
            >
                <Stack horizontal styles={{
                    root: {
                        alignItems: "center"
                    }
                }}>
                    <Stack grow={true} tokens={{padding: 4}} styles={{
                        root: {
                            overflow: 'hidden',
                        }
                    }}>
                        <Text variant={"medium"} title={item.Label}
                              styles={{
                                  root: {
                                      fontWeight: FontWeights.regular, whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      color: branding.theme.palette.white
                                  }
                              }}>
                            {item.Label}
                        </Text>
                    </Stack>
                    {item.manualTag &&
                        <Stack styles={{root: {paddingTop: 4, paddingBottom: 4}}}>

                            <IconButton
                                menuIconProps={{
                                    iconName: "MoreVertical",
                                    style: {fontSize: 16},
                                }}
                                role="button"
                                title="Show actions"
                                data={item}
                                aria-haspopup
                                aria-label="Show actions"
                                styles={iconTagButtonStyles}
                                menuProps={{items: menuItems(item, deleteTag)}}
                            />
                        </Stack>
                    }
                </Stack>
            </Stack>
        </>
    )
}

type FormRuleItemProps = {
    item: IFormAssemblyRule
    index: number
    items: IFormAssemblyRule[]
    onEditModeChange: (editMode: FormRuleEditMode) => void
    handleRemoveCondition: (item:IFormAssemblyRule, index: number) => void
    layouts: ILayout[]
};

const iconTagButtonStyles: Partial<IButtonStyles> = {
    root: {float: "right", height: "inherit", background: "transparent"},
    rootHovered: {background: "transparent"},
    rootPressed: {background: "transparent"},
    icon: {color: "#fff"},
    menuIcon: {color: "#fff"},
};
const iconBarButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,       
        selectors: {
            ":hover": {
                cursor: "pointer",
                backgroundColor: "rgb(243, 242, 241)"
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    icon: {
        color: theme.palette.neutralPrimary,
    }
};

const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },
    correctHeader: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: "center",
            fontWeight: FontWeights.semibold,
        },
    ],
    header: [
        theme.fonts.xLarge,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],

    body: {
        width: "95%",
        height: "75vh",
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        selectors: {
            p: {margin: "14px 0"},
            "p:first-child": {marginTop: 0},
            "p:last-child": {marginBottom: 0},
        },
    },
    bodyMail: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        minHeight: 500,
    },
    containerMail: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "50vw",
    },
    containerSaveModal: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "30vw",
        height: "20vh",
    },
    panelHeaderSubtitle: {
        fontFamily: "Calibri",
        fontWeight: "bold",
        fontSize: 18,
    },
    callout: {
        width: 320,
        maxWidth: "90%",
        padding: "16px 8px",
    },
    labelHelp: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    msLabel: {
        fontWeight: "600",
    },
    buttonBar: {
        display: "flex",
        flexDirection: "column"
    },
    buttonBarSection: {
        display: "flex",
        justifyContent: "space-around",
    },
    formRuleItem: {
        border: "1px solid rgb(64, 102, 146)",
        display: "flex",
        flexDirection: "column",
        marginBottom: '5px'
    },
    formRuleHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px 2px",
        color: "#fff"
    },
    formRuleBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "4px 2px"
    },
    formRuleBodyBtns: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    formAssemblyBeta: {
        padding: "5.5px 2px",
        color: '#a4262c',
    }
});