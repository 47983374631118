import {useContext} from "react"
import {context} from "../context/WizardContext"
import {ILayout} from "../../CustomTemplate"
import {
    DEFAULT_EDIT_MODE,
    FormAssemblyActions,
    FormRuleEditMode,
    IFormAssemblyRule,
    ILayoutAssemblyRule
} from "../../../../interfaces/IFormAssembly"
import React from "react"
import {IStatusMessage} from "../../../../interfaces/IApp"

export const useFormAssembly = () => {

    const {
        editMode, setEditMode, items: layouts, formRules,
        setFormRules
    } = useContext(context)

    const onEditModeChange = (editMode: FormRuleEditMode) => {
        const {rule} = editMode
        let contentControls: ILayoutAssemblyRule[] | undefined = []
        if (!rule) {
            return setEditMode(() => {
                return {...editMode, contentControls: [], rule: undefined}
            })
        }
        if (editMode.type === 'RULES_CONDITION') {

            contentControls = rule?.conditions?.map((condition, index) => {
                const _layout = layouts.find(layout => layout.Id === condition.field)
                return {..._layout, ConditionIndex: index} as unknown as ILayoutAssemblyRule
            })

        }
        
        setEditMode(() => {
            return {...editMode, type: 'RULES_CONDITION', contentControls: contentControls ?? [], rule: rule}
        })
    }

    const addFormAssemblyCC = (control: ILayout, isAdding: boolean) => {
        setEditMode((_editMode) => {

            const items = isAdding ? [..._editMode.contentControls, control] : _editMode.contentControls.filter(x => x.Id !== control.Id)

            return {..._editMode, contentControls: items, rule: editMode.rule}
        })
    }
    const resetEditMode = () => {
        setEditMode((prev) => ({...prev, ...DEFAULT_EDIT_MODE}))
    }


    const handleDisable = (index: number, disable:boolean) => {
        setFormRules((prev) => {
            prev[index].disabled = disable
            return [...prev]
        })
    }
    
    
    /**
     * Function to handle swapping elements in an array.
     *
     * @param {number} index - The index of the element to be swapped.
     * @param {number} newIndex - The index of the element to be swapped with.
     */
    const handleSwap = (index: number, newIndex: number) => {
        let newArr = [...formRules]

        if (newIndex >= 0 && newIndex < formRules.length) {
            [newArr[index], newArr[newIndex]] = [newArr[newIndex], newArr[index]];
            setFormRules([...newArr])
        }
    }

    /**
     * Handles the removal of an item from a list.
     *
     * @param {number} index - The index of the item to be removed.
     * @returns {void}
     */
    const handleRemove = (index: number): void => {
        
        if(editMode.rule !== undefined &&  editMode.rule.ruleName === formRules[index].ruleName) {
            resetEditMode()
        }       
        
        let newArr = [...formRules.slice(0, index), ...formRules.slice(index + 1)];
        setFormRules([...newArr])
    }

    const handleFormAssembly = (option: FormAssemblyActions, rule: IFormAssemblyRule, index?:number): IStatusMessage => {
       

        switch (option) {
            case "CREATE":
     
                const item = formRules.find(i => i.ruleName.trim().toLowerCase() === rule.ruleName.trim().toLowerCase())
                if (option !== 'CREATE' && item === undefined)
                    return {Message: <>That rule is not exists</>, Type: 'error', HasTimeOut: true}
                
                if (item) {
                    return {Message: <>That rule already exists</>, Type: 'error', HasTimeOut: true}
                }
                setFormRules((prev) => [...prev, rule])
                break;
            case "EDIT":
                const updateItem = formRules.find((prevRule, prevIndex) => prevRule.ruleName.trim().toLowerCase() === rule.ruleName.trim().toLowerCase() && prevIndex !== index)
                if (updateItem || index === undefined) {
                    return {Message: <>That rule already exists</>, Type: 'error', HasTimeOut: true}
                }

                setFormRules((prev) => {
                    prev[index].ruleName = rule.ruleName
                    prev[index].adminOnly = rule.adminOnly
                    return [...prev]
                })
                break
            case "SAVE_CONDITIONS":
                
                const existItem = formRules.find((prevRule, prevIndex) => prevIndex !== index && prevRule.ruleName.trim().toLowerCase() === rule.ruleName.trim().toLowerCase())

                if (existItem !== undefined || index === undefined) {
                    return {Message: <>That rule already exists</>, Type: 'error', HasTimeOut: true}
                }
                
                setFormRules((prev) => {

                    prev[index].ruleName = rule.ruleName
                    prev[index].adminOnly = rule.adminOnly
                    prev[index].executeOnManualOnCreate = rule.executeOnManualOnCreate
                    prev[index].executeOnInitOnCreate = rule.executeOnInitOnCreate
                    prev[index].version = rule.version

                    if (Array.isArray(rule.conditions))
                        prev[index].conditions = [...rule.conditions]

                    if (Array.isArray(rule.actions))
                        prev[index].actions = [...rule.actions]

                    return [...prev]
                })


                const _contentControls = rule?.conditions?.map((condition, index) => {
                    const _layout = layouts.find(layout => layout.Id === condition.field)
                    return {..._layout, ConditionIndex: index} as unknown as ILayoutAssemblyRule
                })


                setEditMode(() => {
                    return {...editMode, contentControls: _contentControls ?? [], rule: rule}
                })


                break
        }
        return {Message: <>That rule is already exists</>, Type: 'success', HasTimeOut: true}
    }
    return {
        editMode,
        setEditMode,
        resetEditMode,
        layouts,
        formRules,
        setFormRules,
        onEditModeChange,
        addFormAssemblyCC,
        handleFormAssembly,
        handleSwap,
        handleRemove,
        handleDisable
    }
}