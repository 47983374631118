import React, {useMemo, useState} from 'react'
import {
    ActionButton,
    Checkbox,
    DatePicker,
    DefaultButton,
    DialogFooter,
    Dropdown,
    DropdownMenuItemType,
    FontWeights,
    HighContrastSelector,
    IButtonStyles,
    IconButton,
    IContextualMenuProps,
    IDropdownOption,
    IDropdownStyles,
    IPivotStyles,
    Pivot,
    PivotItem,
    PivotLinkSize,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    TextField
} from '@fluentui/react'


import {Controller, FormProvider, useFieldArray, useForm} from 'react-hook-form'
import {
    conditionColor,
    FormAssemblyOperator,
    IClausule,
    IFormAssemblyRule,
    IRuleElement,
    trueClauseColor,
    userRole
} from '../../../../interfaces/IFormAssembly'
import {IStatusMessage} from '../../../../interfaces/IApp'
import {useFormAssembly} from '../hooks/useFormAssembly'
import StatusMessage from '../../../../share/StatusMessage'
import CustomDialog from "../../../../share/CustomDialog";
import {InputType} from "../../Validations";
import {useBranding} from "../../../../hooks/useBranding";
import {getEnumKeyByEnumValue, isNullOrEmpty, validateConditionRules} from "./utils";
import {getDateWithFormat, getISODateString} from "../../../../Helpers/DateUtils";
import {getDateStrings} from "../../../../Helpers/DateStrings.locale";

type ContidionModalProps = {
    close: () => void,
}


type FormAssemblyConditionType = {
    name: string,
    adminOnly: boolean,
    executeOnInitOnCreate?: boolean,
    executeOnManualOnCreate?: boolean,
    executeOnInitOnEdit?: boolean,
    executeOnEditOnCEdit?: boolean,
    expressions: IRuleElement[]
    actions: IClausule[]
}


export function ConditionModal({close}: ContidionModalProps) {
    const {branding} = useBranding()
    const {editMode, handleFormAssembly, layouts} = useFormAssembly()
    const {contentControls} = editMode
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showRemoveAllConfirmation, setShowRemoveAllConfirmation] = useState(false)
    const [conditionalIndex, setConditionalIndex] = useState(-1)
    const [fieldArraySelected, setFieldArraySelected] = useState("")
    /*const [isCalloutAdminVisible, {toggle: toggleisCalloutAdminVisible}] = useBoolean(false)
    const [isCalloutOnCreateOnInit, {toggle: toggleisCalloutOnCreateOnInit}] = useBoolean(false)
    const [isCalloutOnCreateOnDemand, {toggle: toggleisCalloutOnCreateOnDemand}] = useBoolean(false)*/
    const [pivotSelected, setPivotSelected] = useState('conditions')

    const validContentControls: InputType[] = [
        InputType.TextArea,
        InputType.RichText,
        InputType.CheckBox,
        InputType.Textbox,
        InputType.Number,
        InputType.DropDownList,
        InputType.CheckBoxList,
        InputType.DatePicker,
        InputType.Currency,
    ]

    const validClauseContentControls: InputType[] = [
        InputType.Textbox,
        InputType.TextArea,
        InputType.DropDownList,
        InputType.CheckBoxList,
        InputType.RadioButtonList,
        InputType.DatePicker,
        InputType.Number,
        InputType.Currency,
        InputType.LineBreak,
        InputType.SectionHeader,
        InputType.GridList,
        InputType.Paragraph,
        InputType.RichText,
        InputType.CheckBox,
        InputType.Attachment,
        InputType.Image,
        InputType.HelpLink,
        InputType.Integration
    ]

    /**
     * Represents the options for comparing user roles.
     */
    const userRoleComparisonsOptions: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        return Object.entries(FormAssemblyOperator)
            //
            .filter(([key]) => key === "Equal" || key === "NotEqual")
            .map(([_, value]) => ({
                key: value,
                text: value,
            }))
    }, [FormAssemblyOperator])


    /**
     * Array of dropdown options for check box comparisons.
     * @type {IDropdownOption[]}
     * @returns {IDropdownOption[]} An array of dropdown options.
     */
    const checkBoxComparisonsOptions: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        return Object.entries(FormAssemblyOperator)
            .filter(([key]) => key === getEnumKeyByEnumValue(FormAssemblyOperator, FormAssemblyOperator.Equal) || key === getEnumKeyByEnumValue(FormAssemblyOperator, FormAssemblyOperator.NotEqual))
            .map(([_, value]) => ({
                key: value,
                text: value,
            }))
    }, [FormAssemblyOperator])

    /**
     * A memoized array of options for currency and number comparisons
     *
     * @type {IDropdownOption[]}
     */
    const currencyAndNumberComparisonsOptions: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        return Object.entries(FormAssemblyOperator)
            //
            .filter(([key]) => key !== "EMPTY")
            .map(([_, value]) => ({
                key: value,
                text: value,
            }))
    }, [FormAssemblyOperator])


    /**
     * Holds an array of dropdown options for all comparisons.
     *
     * @type {ReadonlyArray<IDropdownOption>}
     */
    const allComparisonsOptions: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        return Object.entries(FormAssemblyOperator)
            .filter(([key]) => key !== "EMPTY")
            .map(([_, value]) => ({
                key: value,
                text: value,
            }))
    }, [FormAssemblyOperator])


    const customSplitButtonStyles: IButtonStyles = {
        splitButtonMenuButton: {backgroundColor: 'white', width: 28, border: 'none'},
        splitButtonMenuIcon: {fontSize: '7px'},
        splitButtonDivider: {backgroundColor: '#c8c8c8', width: 1, right: 26, position: 'absolute', top: 4, bottom: 4},
        splitButtonContainer: {
            selectors: {
                [HighContrastSelector]: {border: 'none'},
            },
        },
    }


    const fieldNameDropdown: Partial<IDropdownStyles> = {
        dropdown: {width: '100%'},
        dropdownItem: {height: '56px'},
        dropdownItemSelected: {height: '56px'},
        callout: {minWidth: 320}
    }

    const criteriaDropdown: Partial<IDropdownStyles> = {
        dropdown: {width: '100%'},
        callout: {minWidth: 320}
    }

    const conditionOptions: IDropdownOption[] = [
        {key: 'AND', text: 'AND'},
        {key: 'OR', text: 'OR'},
    ]


    const pharenInnerOptions: IDropdownOption[] = [
        {key: '', text: ''},
        {key: '(', text: '('},
    ]


    const pharenOutherOptions: IDropdownOption[] = [
        {key: '', text: ''},
        {key: ')', text: ')'},
    ]


    const pivotStyles: IPivotStyles = {
        link: {},
        linkIsSelected: {
            selectors: {
                ':before': {
                    backgroundColor: pivotSelected === 'conditions' ? conditionColor : trueClauseColor
                },
            },
        },
        root: {},
        icon: {},
        text: {},
        linkContent: {},
        count: {},
        itemContainer: {}

    };

    /**
     * Compares the given `type` and `comparison` and returns the corresponding FormAssemblyOperator.
     * If `comparison` is undefined, it returns the default FormAssemblyOperator.Equal.
     * If `type` is InputType.CheckBox, it returns the FormAssemblyOperator based on checkBoxComparisonsOptions.
     * If `type` is InputType.Currency or InputType.Number, it returns the FormAssemblyOperator based on currencyAndNumberComparisonsOptions.
     * If the corresponding FormAssemblyOperator is found in the comparisons, it returns the `comparison`,
     * otherwise it returns Empty.
     *
     * @param {InputType} type - The type of input.
     * @param {FormAssemblyOperator} comparison - The comparison operator.
     * @returns {FormAssemblyOperator} - The corresponding comparison operator.
     */
    const comparisonIsInOptions = (type: InputType, comparison?: FormAssemblyOperator | ''): FormAssemblyOperator | '' => {
        if (comparison === undefined) return FormAssemblyOperator.Equal

        let comparisons: IDropdownOption[] = allComparisonsOptions
        if (type === InputType.CheckBox) comparisons = checkBoxComparisonsOptions
        if (type === InputType.Currency || type === InputType.Number) comparisons = currencyAndNumberComparisonsOptions

        return comparisons.find(item => item.key === comparison) ? comparison : ''

    }


    /**
     * Represents a variable control.
     *
     * @class
     * @classdesc The VariableControl class allows for manipulating variables, such as assigning values,
     *             retrieving values, and performing arithmetic operations.
     */

    const conditionsForm = useForm<FormAssemblyConditionType>({
        defaultValues: useMemo(() => {

            const ruleExpressions: IRuleElement[] | undefined = editMode.rule?.conditions?.map((expression) => {
                if (expression.field === userRole) {
                    return {
                        field: expression.field,
                        parenOpen: expression?.parenOpen ?? '',
                        parenClose: expression?.parenClose ?? '',
                        operatorGroup: expression?.operatorGroup ?? 'AND',
                        operator: comparisonIsInOptions(InputType.CheckBox, expression?.operator),
                        value: expression?.value ?? ''
                    }
                } else {
                    const contentControl = layouts.find((item) => item.Id === expression.field)
                    if (contentControl !== undefined) {
                        return {
                            field: contentControl?.Id,
                            parenOpen: expression?.parenOpen ?? '',
                            parenClose: expression?.parenClose ?? '',
                            operatorGroup: expression?.operatorGroup ?? 'AND',
                            operator: comparisonIsInOptions(contentControl?.Type, expression?.operator),
                            value: expression?.value ?? ''
                        }
                    } else {
                        return expression
                    }
                }
            })

            const actionsRules: IClausule[] | undefined = editMode.rule?.actions?.map((action) => {
                    return {
                        type: action.type,
                        value: action.value,
                        setValue: action.setValue ?? false,
                        hide: action.hide ?? false,
                        readonly: action.readonly ?? false,
                        fieldValue: action.type === 'tab' ? '' : action.fieldValue ?? ''
                    }
                }
            )


            return {
                name: editMode.rule?.ruleName ?? '',
                adminOnly: editMode.rule?.adminOnly ?? false,
                executeOnInitOnCreate: editMode.rule?.executeOnInitOnCreate === undefined ? true : editMode.rule?.executeOnInitOnCreate,
                executeOnManualOnCreate: editMode.rule?.executeOnManualOnCreate === undefined ? true : editMode.rule?.executeOnManualOnCreate,
                expressions: ruleExpressions,
                actions: actionsRules ?? [],
                falseClause: []
            }

        }, [contentControls])
        , mode: "onSubmit"
    })


    const expressionsFieldArray = useFieldArray({
        control: conditionsForm.control,
        name: "expressions",
    })

    const actionsClauseFieldArray = useFieldArray({
        control: conditionsForm.control,
        name: "actions",
    })


    enum InputTypeAssembly {
        Page = 100,
        UserRole = 101
    }

    const EInputTypeAssembly = {...InputType, ...InputTypeAssembly};


    const expressionsWatchFieldArray = conditionsForm.watch("expressions")
    const actionsWatchFieldArray = conditionsForm.watch("actions")

    const rulesControlledFields = expressionsFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...expressionsWatchFieldArray[index]
        }
    })

    const trueClauseControlledFields = actionsClauseFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...actionsWatchFieldArray[index]
        }
    })


    /**
     * Represents the close action handler for the component.
     * @typedef {function} OnClose
     * @return {void}
     */
    const OnClose = (): void => {
        close()
    }

    /**
     * Swaps the position of two elements in a rulesControlledFields array.
     *
     * @param {number} index - The index of the element to swap.
     * @param {number} newIndex - The new index for the element.
     * @param field
     * @returns {void}
     */
    const handleSwap = (index: number, newIndex: number, field: 'expressions' | 'actions'): void => {
        switch (field) {
            case "expressions":
                if (newIndex >= 0 && newIndex < rulesControlledFields.length) {
                    expressionsFieldArray.swap(index, newIndex)
                }
                break
            case "actions":
                if (newIndex >= 0 && newIndex < trueClauseControlledFields.length) {
                    actionsClauseFieldArray.swap(index, newIndex)
                }
                break
            default:
                break
        }
    }


    /**
     * Handles the removal of a condition.
     *
     * @param {number} index - The index of the condition to be removed.
     * @param fieldArray
     * @returns {void}
     */
    const handleRemoveCondition = (index: number, fieldArray: string): void => {
        setConditionalIndex(index)
        setFieldArraySelected(fieldArray)
        setShowRemoveConfirmation(true)
    }


    /**
     * Sets the fieldArraySelected value to the provided fieldArray and displays the remove all confirmation dialog.
     *
     * @param {string} fieldArray - The field array to set the fieldArraySelected value to.
     * @returns {void}
     */
    const handleRemoveAll = (fieldArray: string): void => {
        setFieldArraySelected(fieldArray)
        setShowRemoveAllConfirmation(true)
    }


    /**
     * Function to add a condition.
     *
     * @function AddCondition
     * @returns {void}
     */
    const AddCondition = (): void => {
        expressionsFieldArray.append({
            field: '',
            parenOpen: '',
            parenClose: '',
            operatorGroup: 'AND',
            operator: FormAssemblyOperator.Equal,
            value: ''
        })
    }


    /**
     * Adds a new action to the actionsClauseFieldArray.
     *
     * @function AddAction
     * @returns {void}
     */
    const AddAction = (): void => {
        actionsClauseFieldArray.append({
            type: 'field',
            value: '',
            setValue: false,
            hide: false,
            readonly: false,
            fieldValue: ''
        })
    }

    /**
     * Removes all items based on the selected field array.
     *
     * @function RemoveAllItems
     * @returns {void} No return value.
     */
    const RemoveAllItems = (): void => {

        switch (fieldArraySelected) {
            case "conditions":
                expressionsFieldArray.remove()
                break
            case "actions":
                actionsClauseFieldArray.remove()
                break
            default:
                break
        }
        setShowRemoveAllConfirmation(false)
    }


    /**
     * Removes a condition from the corresponding field array based on the value of fieldArraySelected.
     *
     * @function RemoveCondition
     * @returns {void}
     */
    const RemoveCondition = (): void => {

        switch (fieldArraySelected) {
            case "conditions":
                expressionsFieldArray.remove(conditionalIndex)
                break
            case "trueClause":
                actionsClauseFieldArray.remove(conditionalIndex)
                break
            default:
                break
        }
        setShowRemoveConfirmation(false)
    }


    /**
     * Generate dropdown options for content control comparisons based on input type.
     *
     * @param {number} index - The index of the content control.
     * @returns {IDropdownOption[]} - An array of dropdown options for content control comparisons.
     */
    const contentControlComparisonsOptions = (index: number): IDropdownOption[] => {

        let comparisonItems: IDropdownOption[] = []
        const contentControlId = conditionsForm.getValues(`expressions.${index}.field` as const)

        if (isNullOrEmpty(contentControlId)) return comparisonItems

        if (contentControlId === userRole) {
            return userRoleComparisonsOptions
        }

        const contentControl = layouts.find(cc => cc.Id === contentControlId)

        switch (contentControl?.Type) {
            case InputType.CheckBox:
                return checkBoxComparisonsOptions
            case InputType.Currency:
                return currencyAndNumberComparisonsOptions
            case InputType.Number:
                return currencyAndNumberComparisonsOptions
            default:
                return allComparisonsOptions
        }
    }


    /**
     * Disables visibility based on index.
     * @param {number} index - The index of the element.
     * @param actionType
     * @returns {boolean | undefined} - Returns boolean value indicating whether visibility is disabled or undefined if input type is not "tab".
     */
    function DisableVisible(index: number, actionType: 'actions'): boolean | undefined {
        const inputType = conditionsForm.getValues(`${actionType}.${index}.type` as const)
        return inputType === 'tab'
    }


    /**
     * Renders the expression field value.
     *
     * @param {any} item - The item object.
     * @param index
     * @param {any} field - The field object.
     * @param {any} fieldState - The field state object.
     * @returns {Element} - The rendered JSX element.
     */
    function RenderExpressionFieldValue(item: any, index: number, field: any, fieldState: any): JSX.Element {
        if (item.field === userRole) {
            return (
                <Dropdown
                    key={item.id}
                    {...field}
                    selectedKey={field.value}
                    required={true}
                    label={""}
                    options={[
                        {key: 'Administrator', text: 'Administrator'},
                    ]}
                    styles={{
                        root: {
                            width: 200
                        }
                    }}
                    onChange={(_, option) => {
                        // @ts-ignore
                        conditionsForm.setValue(`expressions.${index}.value` as const, `${option?.key}`)
                    }}
                    errorMessage={fieldState.error && fieldState.error.message}
                />
            )
        } else {
            return (
                <TextField key={item.id}
                           styles={{root: {width: '100%'}}}
                           placeholder={"Value"}
                           {...field}
                />
            )
        }
    }


    /**
     * Renders the field value based on the provided parameters.
     *
     * @param {any} item - The item to render the field value for.
     * @param {number} index - The index of the item.
     * @param {'actions'} actionType - The type of action.
     * @param {any} field - The field to render the value for.
     *
     * @param fieldState
     * @returns {Element} - The rendered field value as a JSX element.
     */
    function RenderFieldValue(item: any, index: number, actionType: 'actions', field: any, fieldState: any): JSX.Element {

        switch (getFieldType(actionType, index)) {

            case InputType.Number:
                return (
                    <TextField key={item.id}
                               {...field}
                               styles={{root: {minWidth: 300}}}
                               type={"number"}
                               placeholder={""}
                               disabled={IsDisableSetValue(index, actionType)}
                               errorMessage={fieldState.error && fieldState.error.message}
                    />
                )
            case InputType.CheckBox:
                return (
                    <>
                        <Dropdown
                            key={item.id}
                            {...field}
                            selectedKey={field.value}
                            disabled={IsDisableSetValue(index, actionType)}
                            required={true}
                            label={""}
                            options={[
                                {key: 'true', text: 'Checked'},
                                {key: 'false', text: 'UnChecked'}
                            ]}
                            styles={{
                                root: {
                                    width: 300
                                }
                            }}

                            onChange={(_, option) => {
                                // @ts-ignore
                                conditionsForm.setValue(`${actionType}.${index}.fieldValue` as const, `${option?.key}`)
                            }}
                            errorMessage={fieldState.error && fieldState.error.message}
                        />
                    </>
                )
            case InputType.DatePicker:
                return (
                    <DatePicker
                        disabled={IsDisableSetValue(index, actionType)}
                        textField={{
                            name: `${actionType}.${index}.fieldValue` as const,

                        }}
                        isRequired={true}
                        styles={{
                            root: {
                                width: "300px",
                            },
                        }}
                        isMonthPickerVisible={
                            true
                        }
                        formatDate={(date) => {
                            return getDateWithFormat(date, getDateFormat(actionType, index), 'en-us')
                        }}
                        showGoToToday={true}
                        placeholder={
                            "Select a date..."
                        }
                        ariaLabel="Select a date"
                        label=""
                        onSelectDate={(date) => {
                            handleChangeDate(`${actionType}.${index}.fieldValue`, date)
                        }}
                        today={new Date()}
                        strings={getDateStrings('en-US')}
                        defaultValue={undefined}
                        value={getValue(`${actionType}.${index}.fieldValue`, field.value)}
                    />
                )
            default:
                return (
                    <TextField key={item.id}
                               {...field}
                               styles={{root: {minWidth: 300}}}
                               placeholder={""}
                               disabled={IsDisableSetValue(index, actionType)}
                    />
                )
        }


    }


    /**
     * Determines if the setValue field is disabled for a given index and field.
     *
     * @param {number} index - The index of the field.
     * @param {"actions"} field - The field to check ('actions' or 'falseClause').
     * @returns {boolean | undefined} - Returns true if the setValue field is disabled, false if it is enabled, or undefined if the value is not found.
     */
    function IsDisableSetValue(index: number, field: 'actions'): boolean | undefined {
        return !conditionsForm.getValues(`${field}.${index}.setValue` as const)
    }


    /**
     * Disables the ability to set a value in a specified field at a given index.
     *
     * @param {number} index - The index of the field.
     * @param {"actions"} field - The field to disable the ability to set a value for. Can be either "actions" or "falseClause".
     * @returns {boolean | undefined} - Returns true if the value was successfully disabled. Returns undefined if the value cannot be disabled.
     */
    function DisableSetValue(index: number, field: 'actions'): boolean | undefined {
        return !allowSetValue(getFieldType(field, index))
    }

    /**
     * Determines whether or not a given input type allows setting a value.
     *
     * @param {InputType} inputType - The input type.
     * @returns {boolean} - True if the input type allows setting a value, false otherwise.
     */
    const allowSetValue = (inputType: InputType): boolean | undefined => {
        switch (inputType) {
            case InputType.CheckBox:
                return true
            case InputType.CheckBoxList:
                return true
            case InputType.Currency:
                return true
            case InputType.DatePicker:
                return true
            case InputType.DropDownList:
                return true
            case InputType.Number:
                return true
            case InputType.RadioButtonList:
                return true
            case InputType.Textbox:
                return true
            case InputType.RichText:
                return true
            case InputType.TextArea:
                return true
            default:
                return false
        }
    }


    /**
     * Generates an array of dropdown options based on the provided layouts and valid content controls.
     * Each option represents a content control with its associated properties.
     *
     * @param {Array} layouts - The list of layouts to filter and map into dropdown options.
     * @returns {Array} - An array of dropdown options representing content controls.
     */
    const validContentControlsOptions = useMemo<IDropdownOption[]>(() => {

        const temporal = layouts.filter(item => item.Page > 0)

        let items: IDropdownOption[] = temporal.sort((a, b) => a.Page >= b.Page ? 1 : 0)
            .filter(item => validContentControls.includes(item.Type) && item.Page > 0)
            .map(item => ({
                key: item.Id,
                title: item.Label,
                text: item.Label,
                itemType: DropdownMenuItemType.Normal,
                data: {
                    page: Number(item.Page),
                    layoutType: EInputTypeAssembly[item.Type],
                    inputType: item.Type,
                    tagId: item.Id
                }
            }))
            .sort((a, b) => {
                if (a.data.page != b.data.page) return a.data.page - b.data.page;
                else return a.text.localeCompare(b.text, undefined, {sensitivity: 'base'})
            })


        let lastPage = 0;

        const addPagesArray = items.reduce((acc: IDropdownOption[], item: IDropdownOption) => {
            if (item.data.page !== lastPage) {
                lastPage = item.data.page;
                // Insert a new item for a new page
                acc.push({
                    key: `${item.data.page}`,
                    text: `Page ${item.data.page}`,
                    data: {
                        layoutType: EInputTypeAssembly[InputTypeAssembly.Page],
                        inputType: EInputTypeAssembly.Page,
                        tagId: item.data.page
                    },
                    itemType: DropdownMenuItemType.Header
                })

                acc.push({
                    key: `${item.data.page}_divider`,
                    text: '',
                    data: {itemType: 'Divider'},
                    itemType: DropdownMenuItemType.Divider
                })
            }
            acc.push(item);
            return acc;
        }, [])

        addPagesArray.unshift({
            key: userRole,
            text: `Current User Role`,
            data: {
                layoutType: EInputTypeAssembly[InputTypeAssembly.UserRole],
                inputType: EInputTypeAssembly.UserRole,
                tagId: userRole
            },
            itemType: DropdownMenuItemType.Normal
        })

        return addPagesArray

    }, [layouts])


    /**
     * Filters the valid clause content control options based on the given action type and field.
     *
     * @param {("actions")} actionType - The type of action to filter the options for. Can be "actions" or "falseClause".
     * @param {string} field - The field to filter the options for.
     *
     * @returns {Array} - An array of filtered valid clause content control options.
     */
    function FilterValidClauseContentControlsOptions(actionType: 'actions', field: string): IDropdownOption[] {
        const actions = conditionsForm.getValues(actionType)
        return validClauseContentControlsOptions.filter(obj1 => {
            return field === obj1.key || !actions.some(obj2 => obj2.value === obj1.key)
        })
    }


    /**
     * Parses and sets the value based on the input type.
     *
     * @param {InputType} inputType - The type of input.
     * @param {string | undefined} value - The value to be parsed and set.
     * @returns {string | undefined} - The parsed and set value.
     */
    function ParseAndSetValue(inputType: InputType, value: string | undefined): string | undefined {
        const fieldValue = value ?? ''
        switch (inputType) {

            case InputType.CheckBox:
                if (fieldValue.toLowerCase() === 'true' || fieldValue.toLowerCase() === 'false') {
                    return fieldValue
                } else {
                    return ''
                }

            case InputType.Currency:
                if (!isNaN(parseFloat(fieldValue)) || !isNaN(Number(fieldValue))) {
                    return fieldValue
                } else {
                    return '0'
                }
            case InputType.Number:
                if (!isNaN(parseFloat(fieldValue)) || !isNaN(Number(fieldValue))) {
                    return parseInt(fieldValue.replace(/,/g, '')).toString()
                } else {
                    return '0'
                }
            case InputType.DatePicker:
                const date = new Date(fieldValue);
                if (!isNaN(date.getTime())) {
                    return getISODateString(date)
                } else {
                    return getISODateString(new Date())
                }
            default:
                return value
        }
    }


    /**
     * Generates an array of dropdown options based on the provided layouts and valid content controls.
     * Each option represents a content control with its associated properties.
     *
     * @param {Array} layouts - The list of layouts to filter and map into dropdown options.
     * @returns {Array} - An array of dropdown options representing content controls.
     */
    const validClauseContentControlsOptions = useMemo<IDropdownOption[]>(() => {

        const temporal = layouts.filter(item => item.Page > 0)

        let items: IDropdownOption[] = temporal
            .filter(item => validClauseContentControls.includes(item.Type) && item.Page > 0)
            .map(item => ({
                key: item.Id,
                title: item.Label,
                text: item.Label,
                itemType: DropdownMenuItemType.Normal,
                data: {
                    page: Number(item.Page),
                    layoutType: EInputTypeAssembly[item.Type],
                    inputType: item.Type,
                    tagId: item.Id
                }
            }))
            .sort((a, b) => {
                if (a.data.page != b.data.page) return a.data.page - b.data.page;
                else return a.text.localeCompare(b.text, undefined, {sensitivity: 'base'})
            })

        let lastPage = 0;
        return items.reduce((acc: IDropdownOption[], item: IDropdownOption) => {
            if (item.data.page !== lastPage) {
                lastPage = item.data.page;

                acc.push({
                    key: `${item.data.page}`,
                    text: `Page ${item.data.page}`,
                    data: {
                        layoutType: EInputTypeAssembly[InputTypeAssembly.Page],
                        inputType: EInputTypeAssembly.Page,
                        tagId: item.data.page
                    },
                    itemType: DropdownMenuItemType.Normal
                })

                acc.push({
                    key: `_${item.data.page}_divider`,
                    text: '',
                    data: {itemType: 'Divider'},
                    itemType: DropdownMenuItemType.Divider
                })

            }
            acc.push(item);
            return acc;
        }, [])

    }, [layouts])


    /**
     * Returns IContextualMenuProps based on the given index.
     *
     * @param {number} index - The index used to determine the items in the menu.
     * @param field
     * @returns {IContextualMenuProps} The contextual menu properties.
     */
    const menuProps = (index: number, field: 'expressions' | 'actions'): IContextualMenuProps => {
        const myMenu: IContextualMenuProps = {items: [], directionalHintFixed: true}
        let arrayLength: number

        switch (field) {
            case 'expressions':
                arrayLength = rulesControlledFields.length - 1
                break
            case "actions":
                arrayLength = trueClauseControlledFields.length - 1
                break
            default:
                arrayLength = 0
                break
        }


        if (index > 0) {
            myMenu.items.push({
                key: 'up',
                text: 'Up',
                onClick: (_) => handleSwap(index, index - 1, field),
                iconProps: {iconName: 'Up'},
            })
        }

        if (index < arrayLength) {
            myMenu.items.push({
                key: 'down',
                text: 'Down',
                onClick: (_) => handleSwap(index, index + 1, field),
                iconProps: {iconName: 'Down'},
            })
        }

        return myMenu
    }

    /**
     * Renders an icon based on the given input type.
     *
     * @param {InputType | InputTypeAssembly} inputType - The input type to render an icon for.
     * @returns {string} - The name of the icon.
     */
    function RenderIcon(inputType: InputType | InputTypeAssembly): string {
        switch (inputType) {
            case EInputTypeAssembly.TextArea:
                return 'TextBox'
            case EInputTypeAssembly.Textbox:
                return 'TextField'
            case EInputTypeAssembly.Image:
                return 'FileImage'
            case EInputTypeAssembly.Number:
                return 'NumberField'
            case EInputTypeAssembly.Currency:
                return 'Money'
            case EInputTypeAssembly.DatePicker:
                return 'EventDate'
            case EInputTypeAssembly.SectionHeader:
                return 'Header'
            case EInputTypeAssembly.Paragraph:
                return 'List'
            case EInputTypeAssembly.Page:
                return 'Page'
            case EInputTypeAssembly.CheckBox:
                return 'CheckboxComposite'
            case EInputTypeAssembly.DropDownList:
                return 'Dropdown'
            case EInputTypeAssembly.CheckBoxList:
                return 'ReceiptCheck'
            case EInputTypeAssembly.LineBreak:
                return 'Remove'
            case EInputTypeAssembly.GridList:
                return 'LargeGrid'
            case EInputTypeAssembly.Integration:
                return 'EngineeringGroup'
            case EInputTypeAssembly.RichText:
                return 'Font'
            case EInputTypeAssembly.UserRole:
                return 'ReminderPerson'
            default:
                return ''
        }
    }


    /**
     * Renders the given option of a Dropdown component.
     * If the option is not provided, returns null.
     *
     * @param option - The option to be rendered. (optional)
     * @returns The JSX element that represents the rendered option, or null if the option is not provided.
     */
    const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {

        if (!option) return null;
        return (
            <Stack grow={1} horizontal styles={{root: {alignItems: "start"}}} tokens={{childrenGap: 16}}>


                {option.data?.inputType !== undefined &&
                    <Stack>
                        <i className={`ms-Icon ms-Icon--${RenderIcon(option.data?.inputType)}`} aria-hidden="true"></i>
                    </Stack>
                }


                <Stack grow={1}>

                    {option.data?.inputType !== undefined && (option.data?.inputType !== EInputTypeAssembly.Page) ?
                        <Text block nowrap variant={"medium"}
                              styles={{
                                  root: {
                                      fontWeight: option.data?.inputType === EInputTypeAssembly.UserRole ? FontWeights.semibold : FontWeights.regular
                                  }
                              }}
                        >{option.text}</Text>
                        :
                        <Text block nowrap variant={"medium"} styles={{
                            root: {
                                color: branding.theme.semanticColors.menuHeader,
                                fontWeight: FontWeights.semibold
                            }
                        }}>{option.text}</Text>
                    }


                    {option.itemType === DropdownMenuItemType.Normal && option.data?.inputType !== EInputTypeAssembly.UserRole && <>

                        <Text block variant={"small"}
                              styles={{root: {color: branding.theme.semanticColors.disabledText}}}>
                            {option.data?.layoutType} {`[${option.data?.tagId}]`}
                        </Text>

                    </>
                    }

                </Stack>
            </Stack>
        )
    }

    /**
     * Handles the changes in a dropdown field.
     * @param {string} fieldName - The name of the dropdown field.
     * @param {IDropdownOption | undefined} option - The selected option (if any).
     */
    const handleDropdown = (fieldName: string, option?: IDropdownOption | undefined) => {
        //@ts-ignore
        conditionsForm.setValue(fieldName as const, `${option?.key}`)
    }


    /**
     * Function to handle showing a message by resetting the message state to undefined.
     *
     * @returns {void}
     */
    const handleShowMessage = (): void => {
        setMessage(undefined)
    }


    /**
     * Saves the conditions for a form assembly rule.
     *
     * This function is responsible for validating the conditions before saving them.
     * If the conditions are not valid, an error message will be displayed. Otherwise,
     * the conditions will be saved and the form assembly will be updated accordingly.
     *
     * @returns {void}
     */
    const saveConditions = (): void => {
        conditionsForm.handleSubmit(
            (data) => {

                const isValid = validateConditionRules(data.expressions)
                if (!isValid) {
                    setMessage({
                        Type: 'error',
                        Message: <>Condition error: parentheses in the rule are not balanced.</>
                    })
                    return
                }

                if (!validMissingContentControl(data)) {
                    setMessage({
                        Type: 'error',
                        Message: <>Please make sure to fill out all required fields.</>
                    })
                    return
                }

                if (!avoidInfinitelyCycle(data)) {
                    return
                }

                const rule: IFormAssemblyRule = {
                    ...editMode.rule,
                    adminOnly: false,
                    ruleName: data.name.trim(),
                    executeOnInitOnCreate: true,
                    executeOnManualOnCreate: true,
                    conditions: data.expressions,
                    actions: data.actions,
                    version: 3
                }

                let status: IStatusMessage
                if (editMode.rule) {
                    status = handleFormAssembly('SAVE_CONDITIONS', rule, editMode.index)
                } else {
                    status = handleFormAssembly('CREATE', rule)
                }
                if (status.Type === 'error') {
                    return setMessage(status)
                }
                close()
            },
            (_) => {
                setMessage({
                    Type: 'error',
                    Message: <>Please make sure to fill out all required fields.</>
                })
            })()
    }

    /**
     * Finds circular references in a set of form assembly rules.
     *
     * @param {IFormAssemblyRule[]} rules - The array of form assembly rules to analyze.
     * @returns {string[]} - An array of circular paths found in the rules, represented as strings.
     */
    function circularReference(rules: IFormAssemblyRule[]): string[] {
        const graph: Record<string, Set<string>> = {}
        const fieldToRuleMap: Record<string, string> = {}

        // Build the graph
        rules.forEach(rule => {
            rule.conditions.forEach(condition => {
                if (!graph[condition.field]) {
                    graph[condition.field] = new Set()
                }

                fieldToRuleMap[condition.field] = rule.ruleName;

                [...rule.actions].forEach(action => {
                    if (action.type === 'field' && action.setValue === true) {
                        graph[condition.field].add(action.value ?? '')
                    }
                })
            })
        })

        // Detect circular references
        const visited = new Set<string>()
        const recursionStack = new Set<string>()
        const circularPaths: string[] = []

        const dfs = (node: string, path: string[]) => {
            visited.add(node)
            recursionStack.add(node)

            // @ts-ignore
            for (const neighbor of graph[node] || []) {
                if (!visited.has(neighbor)) {
                    if (dfs(neighbor, [...path, neighbor])) {
                        return true
                    }
                } else if (recursionStack.has(neighbor)) {
                    const pathWithRules = [...path, neighbor, node].map(field =>
                        `${field} (${fieldToRuleMap[field] || 'Unknown Rule'})`
                    )
                    circularPaths.push(pathWithRules.join(' -> '))
                    return true
                }
            }

            recursionStack.delete(node)
            return false
        }

        Object.keys(graph).forEach(node => {
            if (!visited.has(node)) {
                dfs(node, [node])
            }
        })
        return circularPaths
    }


    /**
     * Returns the type of field.
     *
     * @param {string} field - The field name. Can be either 'trueClause' or 'falseClause'.
     * @param {number} index - The index of the field.
     * @returns {string} - The type of the field, or 'None' if no type is found.
     */
    const getFieldType = (field: 'actions', index: number): InputType => {
        const fieldName = conditionsForm.getValues(`${field}.${index}.value` as const)
        const contentControl = layouts.find((item) => item.Id === fieldName)
        return contentControl?.Type ?? InputType.None
    }

    /**
     * Retrieves the date format from a specified field and index.
     *
     * @param {('actions')} field - The field to retrieve the date format from. Valid options are: 'actions' or 'falseClause'.
     * @param {number} index - The index of the field.
     * @returns {string} - The retrieved date format.
     */
    const getDateFormat = (field: 'actions', index: number): string => {
        const fieldName = conditionsForm.getValues(`${field}.${index}.value` as const)
        const contentControl = layouts.find((item) => item.Id === fieldName)
        return contentControl?.Validations.Regex ?? ''
    }

    /**
     * Checks if the given condition data avoids an infinity cycle.
     *
     * @param {FormAssemblyConditionType} data - The condition data to be checked.
     * @returns {boolean} - Returns true if the condition data avoids an infinity cycle, otherwise returns false.
     */
    function avoidInfinitelyCycle(data: FormAssemblyConditionType): boolean {

        let myRules = structuredClone(editMode.rules ?? []);

        myRules.forEach((rule) => {
                if (rule.ruleName === editMode.rule?.ruleName) {
                    rule.conditions = data.expressions
                    rule.actions = data.actions
                }
            }
        )

        const circularFields = circularReference(myRules);

        if (circularFields.length > 0) {

            setMessage({
                Type: 'error',
                Message: <>
                    <strong>Circular reference detected in rule paths.</strong> A circular reference occurs when the
                    last rule in a series of references points back to the first, thereby forming an uninterrupted loop:<br/>
                    <ul>
                        {circularFields.map(item => {
                            return (
                                <>
                                    <li>
                                        {item}
                                    </li>
                                </>
                            )
                        })}
                    </ul>
                </>
            })
            return false
        } else {
            return true
        }
    }


    /**
     * Updates the date value for a specific form field.
     *
     * @param {string} id - The ID of the form field.
     * @param {Date|null|undefined} date - The new date value. Can be a Date object, null or undefined.
     *
     * @returns {void}
     */
    const handleChangeDate = (id: string, date: Date | null | undefined): void => {
        let iso = date !== undefined ? getISODateString(date) : undefined
        // @ts-ignore
        conditionsForm.setValue(id as const, iso)
    }


    /**
     * Retrieves a Date value based on the provided id and value.
     *
     * @param {string} id - The id of the value.
     * @param {*} value - The value to be parsed as a Date.
     * @returns {Date} The parsed Date object.
     */
    const getValue = (id: string, value: any): Date | undefined => {

        const dateParsed = Date.parse(value)
        if (isNaN(dateParsed)) {
            let iso = getISODateString(new Date())
            // @ts-ignore
            conditionsForm.setValue(id as const, iso)

            return new Date()

        } else {
            return new Date(value)
        }
    }


    /**
     * Validates if the given data contains valid content controls by checking
     * if the fields in the conditions, trueClause, and falseClause arrays exist
     * in the validContentControlsOptions and validClauseContentControlsOptions arrays.
     *
     * @param {FormAssemblyConditionType} data - The data to be validated
     * @returns {boolean} - True if all content controls are valid, false otherwise
     */
    function validMissingContentControl(data: FormAssemblyConditionType): boolean {

        let validCC = true

        data.expressions.forEach((condition, index) => {
            const existIn = validContentControlsOptions.some(item => item.key === condition.field)
            if (existIn === null || !existIn) {
                validCC = false
                conditionsForm.setError(`expressions.${index}.field` as const, {
                    message: `${condition.field} is not a valid field`,
                    type: 'required'
                })
            }
        })

        data.actions.forEach((trueBlock, index) => {
            const existIn = validClauseContentControlsOptions.some(item => item.key === trueBlock.value)
            if (existIn === null || !existIn) {
                validCC = false
                conditionsForm.setError(`actions.${index}.value` as const, {
                    message: `${trueBlock.value} is not a valid field`,
                    type: 'required'
                })
            }
        })

        return validCC
    }


    return (
        <>
            <Stack grow={1}
                   style={{
                       overflow: "hidden",
                       height: "100%",
                   }}>

                <Stack grow={1} styles={{root: {height: "100%"}}}>

                    {message && <>
                        <Stack styles={{root: {paddingBottom: 16}}}>
                            <StatusMessage setShowMessage={handleShowMessage} status={message.Type}
                                           hasTimer={false} isMultiline={true} dismiss={true}>
                                <>{message.Message}</>
                            </StatusMessage>
                        </Stack>
                    </>
                    }

                    <FormProvider {...conditionsForm}>
                        <form style={{margin: 0, padding: 0}}>

                            <Stack tokens={{childrenGap: 8}}>

                                <Stack>
                                    <Controller name={`name` as const}
                                                control={conditionsForm.control}
                                                rules={{
                                                    validate: {
                                                        required: (value) => {
                                                            if (value === undefined || value.trim().length === 0) return 'Required'
                                                        }
                                                    }
                                                    , maxLength: 50
                                                }}
                                                render={({field, fieldState: {error}}) =>
                                                    <TextField key={'name'}
                                                               placeholder={"Rule Name"}
                                                               label="Rule Name"
                                                               title="Rule Name"
                                                               errorMessage={error && error.message}
                                                               required
                                                               maxLength={50}
                                                               {...field}
                                                    />
                                                }
                                    />
                                </Stack>
                                {/*<Stack horizontal tokens={{childrenGap: 8}}>
                                    <Stack grow={true}>
                                        <Controller name={`adminOnly` as const}
                                                    control={conditionsForm.control}
                                                    render={({field}) =>

                                                        <Checkbox
                                                            key={'adminOnly'}
                                                            onChange={(_, checked) => {
                                                                field.onChange(checked)
                                                            }}
                                                            onRenderLabel={() => {
                                                                return (<>
                                                                    <Stack styles={{root: {alignItems: 'center'}}}
                                                                           horizontal>
                                                                        <Stack>
                                                                            <Text
                                                                                variant={"medium"}
                                                                                styles={{
                                                                                    root: {
                                                                                        paddingLeft: 4
                                                                                    }
                                                                                }}>
                                                                                Rule for administrators
                                                                                only.

                                                                            </Text>
                                                                        </Stack>
                                                                        <Stack>
                                                                            <IconButton
                                                                                id={"adminOnlyCallout"}
                                                                                iconProps={{iconName: 'Info'}}
                                                                                title="Info"
                                                                                ariaLabel="Info"
                                                                                onClick={toggleisCalloutAdminVisible}
                                                                            />
                                                                            {isCalloutAdminVisible && (
                                                                                <Callout
                                                                                    target={'#adminOnlyCallout'}
                                                                                    setInitialFocus
                                                                                    onDismiss={toggleisCalloutAdminVisible}
                                                                                    role="alertdialog"
                                                                                >
                                                                                    <Stack tokens={{padding: 16}}>
                                                                                        <Text variant={"medium"}>
                                                                                            Administrative rules only
                                                                                            will be
                                                                                            executed for Owners and
                                                                                            Co-owners.
                                                                                        </Text>
                                                                                    </Stack>
                                                                                </Callout>
                                                                            )}
                                                                        </Stack>
                                                                    </Stack>
                                                                </>)
                                                            }}
                                                            title="Rule for administrators only"
                                                            checked={field.value !== undefined ? field.value : false}

                                                        />
                                                    }
                                        />
                                    </Stack>


                                    <Stack grow={true}>
                                        <Controller name={`executeOnInitOnCreate` as const}
                                                    control={conditionsForm.control}
                                                    render={({field}) =>

                                                        <Checkbox
                                                            key={'executeOnInitOnCreate'}
                                                            onChange={(_, checked) => {
                                                                field.onChange(checked)
                                                            }}
                                                            onRenderLabel={() => {
                                                                return (<>
                                                                    <Stack styles={{root: {alignItems: 'center'}}}
                                                                           horizontal>
                                                                        <Stack>
                                                                            <Text
                                                                                variant={"medium"}
                                                                                styles={{
                                                                                    root: {
                                                                                        paddingLeft: 4
                                                                                    }
                                                                                }}>
                                                                                On Init (new Document)
                                                                            </Text>
                                                                        </Stack>
                                                                        <Stack>
                                                                            <IconButton
                                                                                id={"executeOnInitOnCreateCallout"}
                                                                                iconProps={{iconName: 'Info'}}
                                                                                title="Info"
                                                                                ariaLabel="Info"
                                                                                onClick={toggleisCalloutOnCreateOnInit}
                                                                            />

                                                                            {isCalloutOnCreateOnInit && (
                                                                                <Callout
                                                                                    target={'#executeOnInitOnCreateCallout'}
                                                                                    setInitialFocus
                                                                                    onDismiss={toggleisCalloutOnCreateOnInit}
                                                                                    role="alertdialog"
                                                                                >
                                                                                    <Stack tokens={{padding: 16}}>
                                                                                        <Text variant={"medium"}>
                                                                                            {"The rule is processed initially when the form is loaded when creating a new Document."}
                                                                                        </Text>
                                                                                    </Stack>
                                                                                </Callout>
                                                                            )}
                                                                        </Stack>
                                                                    </Stack>
                                                                </>)
                                                            }}
                                                            title="On Init (new Document)"
                                                            checked={field.value !== undefined ? field.value : false}
                                                        />
                                                    }
                                        />
                                    </Stack>
                                    <Stack grow={true}>
                                        <Controller name={`executeOnManualOnCreate` as const}
                                                    control={conditionsForm.control}
                                                    render={({field}) =>

                                                        <Checkbox
                                                            key={'executeOnManualOnCreate'}
                                                            onChange={(_, checked) => {
                                                                field.onChange(checked)
                                                            }}
                                                            onRenderLabel={() => {
                                                                return (<>
                                                                    <Stack styles={{root: {alignItems: 'center'}}}
                                                                           horizontal>
                                                                        <Stack>
                                                                            <Text
                                                                                variant={"medium"}
                                                                                styles={{
                                                                                    root: {
                                                                                        paddingLeft: 4
                                                                                    }
                                                                                }}>
                                                                                On Manual (new Document)
                                                                            </Text>
                                                                        </Stack>
                                                                        <Stack>
                                                                            <IconButton
                                                                                id={"executeOnManualOnCreateCallout"}
                                                                                iconProps={{iconName: 'Info'}}
                                                                                title="Info"
                                                                                ariaLabel="Info"
                                                                                onClick={toggleisCalloutOnCreateOnDemand}
                                                                            />
                                                                            {isCalloutOnCreateOnDemand && (
                                                                                <Callout
                                                                                    target={'#executeOnManualOnCreateCallout'}
                                                                                    setInitialFocus
                                                                                    onDismiss={toggleisCalloutOnCreateOnDemand}
                                                                                    role="alertdialog"
                                                                                >
                                                                                    <Stack tokens={{padding: 16}}>
                                                                                        <Text variant={"medium"}>
                                                                                            {"The rule is processed when the user interacts with the form fields when creating a new Document."}
                                                                                        </Text>
                                                                                    </Stack>
                                                                                </Callout>
                                                                            )}
                                                                        </Stack>
                                                                    </Stack>
                                                                </>)
                                                            }}
                                                            title="On Manual (new Document)"
                                                            checked={field.value !== undefined ? field.value : false}
                                                        />
                                                    }
                                        />
                                    </Stack>
                                </Stack>*/}

                            </Stack>


                            <Separator styles={{root: {marginTop: 16, marginBottom: 0}}}>
                                <Text
                                    variant={"medium"}>{"Build conditional statements using specific Content Controls:"}
                                </Text>
                            </Separator>


                            <Pivot aria-label="Assembly Form" linkSize={PivotLinkSize.large}
                                   onLinkClick={(item) => {
                                       setPivotSelected(item!.props.itemKey ?? '')
                                   }}
                                   styles={pivotStyles}>
                                {/* region expressions */}
                                <PivotItem headerText="Expressions" itemKey={"conditions"} style={{paddingTop: 16}}
                                           itemCount={rulesControlledFields.length} alwaysRender={true}>


                                    <Stack className="scrollVisible" data-is-scrollable="true"
                                           style={{
                                               overflowY: "auto",
                                               height: "50vh",
                                           }}>

                                        <table cellPadding={4} style={{minWidth: '640px', maxWidth: '800px'}}>
                                            <thead>
                                            <tr>
                                                <th style={{width: 40}}>{"Options"}</th>
                                                <th style={{width: 56}}>{" "}</th>
                                                <th style={{width: 200}}>{"Search For"}</th>
                                                <th style={{width: 160}}>{"Search Criteria"}</th>
                                                <th style={{width: 160}}>{"Search Term"}</th>
                                                <th style={{width: 56}}>{" "}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rulesControlledFields.length === 0 &&
                                                <>
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <Text variant={"small"}
                                                                  styles={{root: {fontStyle: 'italic'}}}>{"No items to show."}</Text>
                                                        </td>
                                                    </tr>
                                                </>
                                            }
                                            {rulesControlledFields.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={item.id}>
                                                                <td valign={'top'}>
                                                                    {/*remove condition*/}
                                                                    <Controller name={`expressions.${index}` as const}
                                                                                control={conditionsForm.control}
                                                                                render={({field}) =>
                                                                                    <IconButton
                                                                                        key={item.id}
                                                                                        split
                                                                                        menuProps={menuProps(index, 'expressions')}
                                                                                        onClick={(_) => handleRemoveCondition(index, 'conditions')}
                                                                                        iconProps={{iconName: 'Trash'}}
                                                                                        title="Remove"
                                                                                        ariaLabel="Remove"
                                                                                        styles={customSplitButtonStyles}
                                                                                        {...field}
                                                                                    />
                                                                                }
                                                                    />
                                                                </td>
                                                                <td valign={'top'}>
                                                                    {/*parenthetical precedence: OPEN*/}
                                                                    <Controller
                                                                        name={`expressions.${index}.parenOpen` as const}
                                                                        control={conditionsForm.control}
                                                                        render={({field}) =>
                                                                            <Dropdown selectedKey={field.value}
                                                                                      key={item.id}
                                                                                      styles={{root: {width: "100%"}}}
                                                                                      {...field}
                                                                                      onChange={(_, option) => handleDropdown(`expressions.${index}.parenOpen`, option)}
                                                                                      options={pharenInnerOptions}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                                <td valign={'top'}>
                                                                    {/*Content Control*/}
                                                                    <Controller name={`expressions.${index}.field` as const}
                                                                                control={conditionsForm.control}
                                                                                rules={{required: "Required"}}
                                                                                render={({
                                                                                             field,
                                                                                             fieldState: {error}
                                                                                         }) =>
                                                                                    <Dropdown
                                                                                        selectedKey={field.value}
                                                                                        key={item.id}
                                                                                        errorMessage={error && error.message}
                                                                                        styles={fieldNameDropdown}
                                                                                        {...field}
                                                                                        onChange={(_, option) => handleDropdown(`expressions.${index}.field`, option)}
                                                                                        options={validContentControlsOptions}
                                                                                        onRenderOption={onRenderOption}
                                                                                    />
                                                                                }
                                                                    />
                                                                </td>
                                                                <td valign={'top'}>
                                                                    {/*Comparisons*/}
                                                                    <Controller
                                                                        name={`expressions.${index}.operator` as const}
                                                                        control={conditionsForm.control}
                                                                        rules={{required: "Required"}}
                                                                        render={({field, fieldState: {error}}) =>
                                                                            <Dropdown styles={criteriaDropdown}
                                                                                      key={item.id}
                                                                                      selectedKey={field.value}
                                                                                      {...field}
                                                                                      errorMessage={error && error.message}
                                                                                      onChange={(_, option) => handleDropdown(`expressions.${index}.operator`, option)}
                                                                                      options={contentControlComparisonsOptions(index)}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                                <td valign={'top'}>


                                                                    {/*Value to compare*/}
                                                                    <Controller name={`expressions.${index}.value` as const}
                                                                                control={conditionsForm.control}
                                                                                rules={{

                                                                                    validate: {
                                                                                        required: (value: string) => {
                                                                                            const fieldSetValue = conditionsForm.getValues(`expressions.${index}.field` as const)
                                                                                            if (fieldSetValue === userRole) {
                                                                                                if (value === undefined || value.trim().length === 0) return 'Required'
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                render={({
                                                                                             field,
                                                                                             fieldState
                                                                                         }) => RenderExpressionFieldValue(item, index, field, fieldState)
                                                                                }
                                                                    />
                                                                </td>
                                                                <td valign={'top'}>
                                                                    {/*parenthetical precedence: CLOSE*/}
                                                                    <Controller
                                                                        name={`expressions.${index}.parenClose` as const}
                                                                        control={conditionsForm.control}
                                                                        render={({field}) =>
                                                                            <Dropdown selectedKey={field.value}
                                                                                      key={item.id}
                                                                                      styles={{root: {width: '100%'}}}
                                                                                      {...field}
                                                                                      onChange={(_, option) => handleDropdown(`expressions.${index}.parenClose`, option)}
                                                                                      options={pharenOutherOptions}
                                                                            />}
                                                                    />
                                                                </td>
                                                            </tr>

                                                            {/*Logical operators*/}
                                                            {expressionsFieldArray.fields.length !== 1 && index !== expressionsFieldArray.fields.length - 1 &&
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={6}>
                                                                            <Separator>
                                                                                <Controller
                                                                                    name={`expressions.${index}.operatorGroup` as const}
                                                                                    control={conditionsForm.control}
                                                                                    render={({field}) =>
                                                                                        <Dropdown selectedKey={field.value}
                                                                                                  key={item.id}
                                                                                                  styles={{root: {width: '80px'}}}
                                                                                                  {...field}
                                                                                                  onChange={(_, option) => handleDropdown(`expressions.${index}.operatorGroup`, option)}
                                                                                                  options={conditionOptions}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            </Separator>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            }

                                                        </>
                                                    )
                                                }
                                            )
                                            }
                                            </tbody>
                                        </table>

                                        <Stack styles={{root: {paddingTop: 16}}} horizontal>
                                            <Stack>
                                                <ActionButton
                                                    iconProps={{iconName: 'Add'}}
                                                    allowDisabledFocus
                                                    onClick={AddCondition}>
                                                    Add expression
                                                </ActionButton>
                                            </Stack>
                                            <Stack grow={true}>
                                                {" "}
                                            </Stack>
                                            {rulesControlledFields.length > 0 &&
                                                <Stack>

                                                    <ActionButton
                                                        iconProps={{iconName: 'Trash'}}
                                                        allowDisabledFocus
                                                        onClick={() => handleRemoveAll('conditions')}>
                                                        Clear All
                                                    </ActionButton>
                                                </Stack>
                                            }
                                        </Stack>


                                    </Stack>

                                </PivotItem>
                                {/*endregion*/
                                }


                                {/*region Actions*/
                                }
                                <PivotItem headerText="Actions" itemKey={"trueClause"}
                                           style={{paddingTop: 0}}
                                           itemCount={trueClauseControlledFields.length}
                                           alwaysRender={true}>
                                    <Stack className="scrollVisible" data-is-scrollable="true"
                                           styles={{root: {paddingBottom: 40}}}
                                           style={{
                                               overflow: "hidden",
                                               overflowY: "auto",
                                               height: "50vh",
                                           }}>

                                        <table cellPadding={4}
                                               style={{minWidth: '640px', maxWidth: '800px'}}>
                                            <thead>
                                            <tr>
                                                <th style={{width: 40}}>{"Options"}</th>
                                                <th style={{width: 200}}>{"Element"}</th>
                                                <th style={{width: 80}}>{"Read-Only"}</th>
                                                <th style={{width: 40}}>{"Hide"}</th>
                                                <th style={{width: 300}}>{"Value"}</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {trueClauseControlledFields.length === 0 &&
                                                <>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <Text variant={"small"}
                                                                  styles={{root: {fontStyle: 'italic'}}}>{"No items to show."}</Text>
                                                        </td>
                                                    </tr>
                                                </>
                                            }

                                            {trueClauseControlledFields.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={item.id}>

                                                            <td valign={'top'}>
                                                                {/*remove condition*/}
                                                                <Controller
                                                                    name={`actions.${index}` as const}
                                                                    control={conditionsForm.control}
                                                                    render={({field}) =>
                                                                        <IconButton
                                                                            key={item.id}
                                                                            split
                                                                            menuProps={menuProps(index, 'actions')}
                                                                            onClick={(_) => handleRemoveCondition(index, 'trueClause')}
                                                                            iconProps={{iconName: 'Trash'}}
                                                                            title="Remove"
                                                                            ariaLabel="Remove"
                                                                            styles={customSplitButtonStyles}
                                                                            {...field}
                                                                        />
                                                                    }
                                                                />
                                                            </td>


                                                            <td valign={'top'}>
                                                                {/*Content Control*/}

                                                                <Controller
                                                                    name={`actions.${index}.value` as const}
                                                                    control={conditionsForm.control}
                                                                    rules={{required: "Required"}}
                                                                    render={({
                                                                                 field,
                                                                                 fieldState: {error}
                                                                             }) =>
                                                                        <Dropdown
                                                                            selectedKey={field.value}
                                                                            key={item.id}
                                                                            errorMessage={error && error.message}
                                                                            styles={fieldNameDropdown}
                                                                            {...field}
                                                                            onChange={(_, option) => {

                                                                                // @ts-ignore
                                                                                conditionsForm.setValue(`actions.${index}.type` as const, option.data.inputType === InputTypeAssembly.Page ? 'tab' : 'field')

                                                                                if (option?.data.inputType === InputTypeAssembly.Page) {
                                                                                    // @ts-ignore
                                                                                    conditionsForm.setValue(`actions.${index}.hidden` as const, true)
                                                                                }

                                                                                if (!allowSetValue(option?.data.inputType)) {
                                                                                    // @ts-ignore
                                                                                    conditionsForm.setValue(`actions.${index}.fieldValue` as const, '')
                                                                                    // @ts-ignore
                                                                                    conditionsForm.setValue(`actions.${index}.setValue` as const, false)
                                                                                } else {
                                                                                    const currentValue = conditionsForm.getValues(`actions.${index}.fieldValue` as const)
                                                                                    const newValue = ParseAndSetValue(option?.data.inputType, currentValue)

                                                                                    // @ts-ignore
                                                                                    conditionsForm.setValue(`actions.${index}.fieldValue` as const, newValue)
                                                                                }
                                                                                handleDropdown(`actions.${index}.value`, option)
                                                                            }}
                                                                            options={FilterValidClauseContentControlsOptions('actions', field.value)}
                                                                            onRenderOption={onRenderOption}
                                                                        />
                                                                    }
                                                                />
                                                            </td>

                                                            <td valign={'top'} style={{textAlign: 'center'}}>
                                                                <Controller
                                                                    name={`actions.${index}.readonly` as const}
                                                                    control={conditionsForm.control}
                                                                    render={({field, fieldState: {}}) =>

                                                                        <Checkbox label={undefined}
                                                                                  key={item.id}
                                                                                  checked={field.value}
                                                                                  styles={{root: {margin: '6px 24px'}}}
                                                                                  title={"Select Read-Only disables field selection and the reverse enables field selection"}
                                                                                  {...field}/>


                                                                    }
                                                                />
                                                            </td>

                                                            <td valign={'top'}>
                                                                <Controller
                                                                    name={`actions.${index}.hide` as const}
                                                                    control={conditionsForm.control}
                                                                    render={({field, fieldState: {}}) =>

                                                                        <Checkbox label={undefined}
                                                                                  key={item.id}
                                                                                  checked={field.value}
                                                                                  disabled={DisableVisible(index, 'actions')}
                                                                                  styles={{root: {margin: '6px 8px'}}}
                                                                                  title={"Select Hides makes the field non visible and the reverse shows the field"}
                                                                                  {...field}/>

                                                                    }
                                                                />
                                                            </td>

                                                            <td valign={'top'}>

                                                                <Stack horizontal wrap={false}>
                                                                    <Stack>
                                                                        <Controller
                                                                            name={`actions.${index}.setValue` as const}
                                                                            control={conditionsForm.control}
                                                                            render={({
                                                                                         field,
                                                                                         fieldState: {}
                                                                                     }) =>

                                                                                <Checkbox
                                                                                    label={undefined}
                                                                                    key={item.id}
                                                                                    checked={field.value}
                                                                                    disabled={DisableSetValue(index, 'actions')}
                                                                                    styles={{root: {margin: '6px 8px'}}}
                                                                                    title={"Checking this sets a value to the control"}
                                                                                    {...field}/>

                                                                            }
                                                                        />
                                                                    </Stack>
                                                                    <Stack>
                                                                        <Controller
                                                                            name={`actions.${index}.fieldValue` as const}
                                                                            control={conditionsForm.control}
                                                                            rules={{

                                                                                validate: {
                                                                                    required: (value: string) => {

                                                                                        const fieldSetValue = conditionsForm.getValues(`actions.${index}.setValue` as const)
                                                                                        if (fieldSetValue === true) {

                                                                                            switch (getFieldType('actions', index)) {
                                                                                                case InputType.Number:
                                                                                                    if (value === undefined || value.trim().length === 0) return 'Required'
                                                                                                    break
                                                                                                case InputType.Currency:
                                                                                                    if (value === undefined || value.trim().length === 0) return 'Required'
                                                                                                    break
                                                                                                case InputType.DatePicker:
                                                                                                    if (value === undefined || value.trim().length === 0) return 'Required'
                                                                                                    break
                                                                                                case InputType.CheckBox:
                                                                                                    if (value === undefined || value.trim().length === 0) return 'Required'
                                                                                                    break
                                                                                                default:
                                                                                                    break
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }}

                                                                            render={({
                                                                                         field,
                                                                                         fieldState
                                                                                     }) => RenderFieldValue(item, index, 'actions', field, fieldState)}
                                                                        />

                                                                    </Stack>
                                                                </Stack>


                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                            </tbody>
                                        </table>


                                        <Stack styles={{root: {paddingTop: 16}}} horizontal>
                                            <Stack>
                                                <ActionButton iconProps={{iconName: 'Add'}}
                                                              allowDisabledFocus
                                                              onClick={AddAction}>
                                                    Add expression
                                                </ActionButton>
                                            </Stack>
                                            <Stack grow={true}>
                                                {" "}
                                            </Stack>
                                            {trueClauseControlledFields.length > 0 &&
                                                <Stack>

                                                    <ActionButton
                                                        iconProps={{iconName: 'Trash'}}
                                                        allowDisabledFocus
                                                        onClick={() => handleRemoveAll('actions')}>
                                                        Clear All
                                                    </ActionButton>
                                                </Stack>
                                            }
                                        </Stack>

                                    </Stack>
                                </PivotItem>
                                {/*endregion*/
                                }
                            </Pivot>
                        </form>
                    </FormProvider>

                </Stack>
            </Stack>

            {/*region FOOTER*/
            }
            <Separator styles={{root: {marginBottom: -8}}}></Separator>
            <DialogFooter>
                <PrimaryButton id="btnCreateUpdate" onClick={saveConditions}
                               text="Save Conditions"
                               title="Save Conditions"/>
                <DefaultButton id="btnCancel" onClick={OnClose} text={'Cancel'}
                               title={'Cancel'}/>
            </DialogFooter>

            {/*endregion*/
            }


            {/*region Remove condition*/
            }
            <CustomDialog title={'Delete Confirmation'}
                          titleId={`DeleteConfirmationConditional`}
                          actionText={'Yes'}
                          confirm={RemoveCondition}
                          close={() => {
                              setShowRemoveConfirmation(false)
                          }}
                          isDisabled={false}
                          reversePrimary={true}
                          isOpen={showRemoveConfirmation}
            >
                <>
                    <Text variant={"medium"}>
                        Do you want to delete this condition?
                    </Text>
                </>
            </CustomDialog>
            {/*endregion*/
            }

            {/*region Remove all conditions*/
            }
            <CustomDialog title={'Delete Confirmation'}
                          titleId={`DeleteConfirmationAll`}
                          actionText={'Yes'}
                          confirm={RemoveAllItems}
                          close={() => {
                              setShowRemoveAllConfirmation(false)
                          }}
                          isDisabled={false}
                          reversePrimary={true}
                          isOpen={showRemoveAllConfirmation}
            >
                <>
                    <Text variant={"medium"}>
                        Do you want to delete All items?
                    </Text>
                </>
            </CustomDialog>
            {/*endregion*/
            }

        </>
    )
}



