import useFetch from "./Api/UseFetch";
import { IListDetail } from "./Models/IListDetails";
import axios from "axios";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { textFieldStyle } from "./RHFControls/Styles";

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { FontWeights, getTheme, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { IIconProps } from "@fluentui/react/lib/Icon";
import "../../../Custom.css";
import { IconButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import Helper, { IAudienceIntersect, ICheckedValues, ICreateDocumentRequest, IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceRequest, IFormControl, OperationType } from "../../../Helpers/Helper";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import { Separator, Stack } from "office-ui-fabric-react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { ILayout, Integration } from "../CustomTemplate";
import { getResetValueByType, InputType, RuleActionType } from "../Validations";
import { isMobile } from "react-device-detect";
import { ControlledTextField } from "../../../Fluent-RHF/ControllerTextField";
import PreviewScreen from "../../Preview/PreviewScreen";
import { IStackTokens } from "@fluentui/react";
import { ICell, IList, IRow } from "./RHFControls/Grid/Models";
import ReactDOM from "react-dom";
import DraftServices from "../../DraftDocuments/Services/DraftServices";
import { IRule } from "./RHFControls/RHFUploadRunTimeControl";
import {
    datePickerFormats,
    DEFAULT_FORM_FORMAT,
    getDateFromFormat,
    getISODateString,
    getTodaysISODate,
    setGlobalDateLocale
} from "../../../Helpers/DateUtils";
import { IDocumentImageResponse } from "./Models/DocumentImageResponse";
import Attachment from "../../Attachments/Attachment";
import AttachmentService from "../../../services/attachments";
import StatusMessage, { StatusEnum } from "../../../share/StatusMessage";
import { getLocaleValid } from "../../../Helpers/DateStrings.locale";
import { useTranslation } from "react-i18next";
import HttpRequest from "../../../services/HttpRequest";
import { DisplayType } from "../../OutboundIntegration/Models/Enums";
import OutboundService from "../../../services/outbound";
import { IUseFetchProps } from "./Api/UseFetchProps";
import DocumentAssemblyService from "../../../services/assembly";
import { IContentLibrary, IContentLibraryLanguage } from "../../../interfaces/IDocumentAssembly";
import { useBranding } from "../../../hooks/useBranding";
import FormHeader from "./FormHeader";
import ResponsiveModal from "../../../share/ResponsiveModal";
import ListControls from "./RHFControls/ListControls";
import CustomFormProvider from "./context/CustomFormContext";

import { customAppContext } from "../../../context/AppContext";
import { ActionType, IFormAssemblyRule } from "../../../interfaces/IFormAssembly";
import { cleanHtml, getValidations, validations } from "./RHFControls/utils/functions";
import { useSignalR } from "../../../hooks/useSignalR";
import FormPaginate from "./FormPaginate";
import { PageItem } from "../../../share/CustomPaginate";
import { useDocument } from "../../../hooks/useDocument";
import ListService from "../../../services/List";
import UserService from "../../../services/User";
import { cloneDeep } from "lodash";
import {IOutputMap} from "../../../interfaces/IOutbound";

const theme = getTheme();

const DEFAULT_NOT_LOADED_TEXT = ""
const DEFAULT_NOT_LOADED_GRID = " "


type getGridRowsType = ({ [key: string]: IList })

type Props = {
    [key: string]: any;
};

export const CustomForm: FC<Props> = ({ ...props }: Props) => {
    const { getTextboxValue, profile, setProfile } = useDocument()
    const { branding } = useBranding()
    const isAccessCodeValid = props.sendlink ? props.isAccessCodeValid : true
    const contentStyles = mergeStyleSets({
        pagination: {
            padding: 0,
            selectors: {
                "ul": {
                    display: "inline-block"
                },
                "li": {
                    fontSize: "12pt",
                    color: branding.theme.palette.themeDarker,
                    display: "inline-block",
                    border: `solid 1px ${branding.theme.palette.themeDarker}`,
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    borderCollapse: "collapse",
                    fontWeight: "500"
                }
            }
        },
        active: {
            backgroundColor: branding.theme.palette.themeDarker,
            selectors: {
                "a": {
                    color: branding.theme.palette.white
                }
            }
        },
        container: {
            borderTop: branding.backgroundColor === undefined ? `4px solid  ${branding.theme.palette.themePrimary}` : `4px solid ${branding.backgroundColor}`,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        container2: {
            borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            overflowY: "hidden",
            width: "100%",
            height:
                window.innerHeight > 660
                    ? "63vh"
                    : isMobile
                        ? "52vh"
                        : window.innerHeight < 600
                            ? "50vh"
                            : "58vh",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        bodyPanel: {
            flex: "4 4 auto",
            overflowY: "hidden",
            width: "100%",
            height:
                window.innerHeight > 660
                    ? "60vh"
                    : isMobile
                        ? window.innerHeight > 760
                            ? "75vh"
                            : "52vh"
                        : window.innerHeight < 600
                            ? "45vh"
                            : "55vh",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        callout: {
            maxWidth: 300,
        },
        buttonArea: {},
        inner: {
            height: "100%",
            padding: "0 24px 20px",
        },
    });
    const { t } = useTranslation(["common", "wizard", "preview"]);

    const [stateManagement, setStateManagement] = useState<any>({});
    const [layouts, setLayouts] = useState<ILayout[]>([]);
    const [originalLayouts, setOriginalLayouts] = useState<Array<any>>([])    
    const [customDropDownLists, setCustomDropDownLists] = useState<{
        [key: string]: IDropdownOption[];
    }>({});
    const [integrationDropDownLists, setIntegrationDropDownLists] = useState<{
        [key: string]: IDropdownOption[];
    }>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [forcePage, setForcePage] = useState(0);
    const locale = useRef("en-US");
    const [documentValues, setDocumentValues] = useState<IFormControl[]>([]);
    const [applicationInfoLoaded, setApplicationInfoLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [templateXML, setTemplateXML] = useState("");
    const [gridListRows, setGridListRows] = useState<{ [key: string]: IList }>(
        {}
    );
    const [checkBoxCCs, setCheckBoxCCs] = useState<string[]>([])
    const customUseForm = useForm<any, any>({
        defaultValues: {},
        reValidateMode: "onSubmit",
        mode: "all",
    });

    // const { data: applicationResponse, isLoading: loadingApplication } = useFetch<any>();
    const [applicationResponse, setApplicationResponse] = useState<any[] | undefined>([])
    const [loadingApplication, setLoadingApplication] = useState(true)

    const showPagination = +stateManagement?.Pages! > 1;
    const previewIcon: IIconProps = { iconName: "DocumentSearch" };
    const attachIcon: IIconProps = { iconName: "Attach" };
    const [controlsToPrint, setControlsToPrint] = useState<{
        [key: string]: JSX.Element;
    }>({});
    const [imageValues, setImageValues] = useState<{ [key: string]: IRule }>({});
    const [imageValuesDefault, setImageValuesDefault] = useState<{ [key: string]: IRule }>({});


    const [applying, setApplying] = useState(false);
    const stackTokens = { childrenGap: 15 };
    const [spinnerMessage, setSpinnerMessage] = useState("");
    const [submitDisable, setSubmitDisable] = useState(false);

    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState("");
    const [previewMime, setPreviewMime] = useState("");
    const [spinnerText, setSpinnerText] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [showAttachment, setShowAttachment] = useState(false);
    const [showAttachmentBtn, setShowAttachmentBtn] = useState(false);
    const [validAttachments, setValidAttachments] = useState([]);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [contentLibraryOptions, setContentLibraryOptions] = useState<IContentLibrary[]>([])
    const [successMessage, setSuccessMessage] = useState("");
    const [linkDraftId, setLinkDraftId] = useState<number>(0)
    const [currentFormControls, setCurrentFormControls] = useState<IFormControl[]>([])
    const [formRules, setFormRules] = useState<IFormAssemblyRule[]>([])
    const isQueue = useRef(false)
    const queueId = useRef("")

    const signalR = useSignalR()

    const contentLibrary = useRef<{ [key: string]: IContentLibraryLanguage[] }>({});
    const [firstLoading, setFirstLoading] = useState(true);
    let  integrationTriggered = false
    
    useEffect(() => {
        if (errorMessage !== "") {
            setTimeout(() => {
                setErrorMessage("")
            }, 10000);
        }
    }, [errorMessage])

    useEffect(() => {
        if (successMessage !== "") {
            setTimeout(() => {
                setSuccessMessage("")
            }, 5000);
        }
    }, [successMessage])


    const [docSource, setDocSource] = useState(() => {
        return props.isSubform && props.sendlink
            ? "subformlink"
            : props.isSubform
                ? "subform"
                : props.isCapaign
                    ? "campaignlink"
                    : props.sendlink
                        ? "link"
                        : "normal";
    });

    const hideAttachment = () => {
        setShowAttachment(false);
    };

    const onSave = (items?: PageItem[]) => {
        setSubmitDisable(true)
        if (showAttachmentBtn) {
            AttachmentService.getValidationAttachments({
                DocumentId: isUpdate ? props.documentId : -1,
                TemplateId: props.templateId,
                DocSource: docSource,
                DocumentDraftId: props.draftId,
                Username: props.username,
                EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.targetUser,
            }).then((validations: any) => {
                if (validations?.length > 0) {
                    setApplying(false)
                    setValidAttachments(validations);
                    setShowAttachment(true);
                    return;
                }
                handleSubmit(items ?? []);
            });
        } else {
            handleSubmit(items ?? []);
        }
    };
    const handleSubmit = (items: PageItem[]) => {
        customUseForm.handleSubmit(
            (data) => {
                submitForm(getFormControls(data));
            },
            (error) => {
                setSubmitDisable(false)

                var shortcutError: string[] = Object.keys(error);

                if (shortcutError.indexOf("_shortcutReference") !== -1) {
                    document.getElementById("_shortcutReference")!.focus();
                    return
                }

                const errorEnabled = Object.keys(error).filter((err) => {
                    const item = layouts.find(cc => cc.Id === err)
                    if (!item) return false

                    const isDisabled = items.find(page => page.name === `${item.Page}` && page.disabled)
                    return !isDisabled
                })
                const currentPageKeys = errorEnabled.filter(
                    (key) =>
                        layouts
                            .filter((item) => +item.Page === currentPage)
                            .map((control) => control.Id)
                            .includes(key) || key === "_shortcutReference"
                );
                if (currentPageKeys.length > 0) {
                    document.getElementById(currentPageKeys[0])?.focus();
                           } else {
                    if (errorEnabled.length === 0) {
                        customUseForm.clearErrors()
                        console.log("----- customUseForm -----")
                        console.log(customUseForm)
                        submitForm(getFormControls(customUseForm.getValues()));
                        return
                    }
                    const nextPage = +layouts.filter((item) => item.Id === errorEnabled[0])[0].Page;
                    setForcePage(nextPage - 1);
                    setCurrentPage(nextPage);
                }
            }
        )();
    };
    const getImageValues = (contentControls: IFormControl[]): IRule[] => {
        const images = Object.entries(imageValues).map(([k, v]) => {
            const cc = contentControls.find(x => x.Key === k)
            if (cc?.Value === DEFAULT_NOT_LOADED_TEXT) {
                return { ...v, CCVaue: '' }
            }
            return v
        })
        return images
    }

    const getGridRows = (contentControls: IFormControl[]): getGridRowsType => {

        const filteredRows = Object.entries(gridListRows)
            .filter(([key, row]) => contentControls.some(cc => cc.Key === row.IdList && cc.Value !== DEFAULT_NOT_LOADED_GRID))
            .reduce<({ [key: string]: IList })>((result, [key, row]) => {
                result[key] = row;
                return result;
            }, {});

        return filteredRows;
    }

    const getFormControls = (data: any): IFormControl[] => {
        let keys = Object.keys(data);
        let values = Object.values(data);
        let formControls: IFormControl[] = [];

        // const assemblyActions = formRules.length > 0 ? evaluateFormAssembly(formRules, getIsAdmin(), data, layouts) : []
        keys.forEach((key, keyIndex) => {
            let internalLayouts = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children.length > 0 ? el.Children : el), []);
            let currentLayout = internalLayouts.find((l) => l.Id === key);
            let formControl: IFormControl = { Key: key, Value: "" };
            switch (currentLayout === undefined ? InputType.Textbox : currentLayout.Type) {
                case InputType.CheckBoxList:
                case InputType.DropDownList:
                    if (Array.isArray(values[keyIndex])) {
                        formControl.Value = values[keyIndex] === undefined ? "" : (values[keyIndex] as IDropdownOption[]).map((item) => item.text).join(",");
                        const dropdownObject = values[keyIndex] as IDropdownOption[];
                        formControl.CheckedValues = dropdownObject.map((x) => {
                            return { Key: x.key, Text: x.text };
                        })

                    } else if (values[keyIndex] instanceof Object) {
                        if (values[keyIndex] === undefined) {
                            formControl.Value = "";
                            break;
                        }
                        const dropdownObject = values[keyIndex] as IDropdownOption;
                        formControl.Value = dropdownObject.text;
                        formControl.CheckedValues = [];
                        formControl.CheckedValues = [
                            ...formControl.CheckedValues,
                            {
                                Key: dropdownObject.key,
                                Text: dropdownObject.text,
                            },
                        ];
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                case InputType.DatePicker:
                    const dateFormated =
                        values[keyIndex] != undefined
                            ? getDateFromFormat(
                                (values[keyIndex] as string,
                                    currentLayout?.Validations.Regex ?? "")
                                    .toString()
                                    .trimEnd()
                            )
                            : "";
                    formControl.Value =
                        dateFormated === ""
                            ? getISODateString(values[keyIndex] as Date)
                            : dateFormated;
                    break;
                case InputType.CheckBox:
                    if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === currentLayout?.Id) !== undefined) {
                        if (values[keyIndex] === undefined || values[keyIndex] === "false") {
                            formControl.Value = "";
                        } else if ((values[keyIndex] as string).toString() === "true") {
                            formControl.Value = "true"
                        } else {
                            formControl.Value = "";
                        }
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                //case InputType.Image:
                //formControl.Value = "";
                //break;
                case InputType.Textbox:
                    formControl.Value = values[keyIndex] != undefined ? (values[keyIndex] as string).toString().trimEnd() : "";
                    break;
                default:
                    formControl.Value = values[keyIndex] as string;
            }
            /*if (assemblyActions.length > 0) {
                const actions = assemblyActions.filter(action => action.type === 'field' && action.value === key)
                const tabActions = assemblyActions.filter(action => action.type === 'tab' && Number(action.value) === Number(currentLayout?.Page) - 1)
                if ((actions.length > 0 || tabActions.length > 0)) {
                    let tempStatus = { id: key, value: formControl.Value, status: ActionType.Show }
                    const _actions = [...actions, ...tabActions]
                    _actions.forEach((actionToRun) => {
                        if (actionToRun) {
                            tempStatus.status = actionToRun.actionType as ActionType
                            if (actionToRun.actionType === ActionType.Value) {
                                //console.log('id', state.id, 'type', action.payload.type)
                                tempStatus.value = actionToRun.fieldValue ?? ''
                            }
                            //tempStatus.value = getResetValueByType(tempStatus.value, action.payload.type, actionToRun.actionType)
                            tempStatus.id = actionToRun.value ?? ''
                        }

                    })
                    const resultValue = getResetValueByType(tempStatus.value, currentLayout?.Type, tempStatus.status)
                    if (currentLayout?.Type === InputType.CheckBoxList) {
                        formControl.CheckedValues = [];
                    }
                    formControl.Value = resultValue
                    if (currentLayout?.Type === InputType.Image && tempStatus.status === ActionType.Hide) {
                        formControl.Value = DEFAULT_NOT_LOADED_TEXT
                    }
                }
            }*/
            formControls.push(formControl);
        });

        return formControls;
    };

    const submitForm = async (formControls: IFormControl[]) => {
        var documentId = "-1";
        const { Released } = stateManagement;
        if (isUpdate) {
            setSpinnerMessage(t("text.spinnerUpdating", { ns: "common" }));
            documentId = props.documentId;
        } else setSpinnerMessage(t("text.spinnerCreating", { ns: "common" }));
        setApplying(true)
        var data: any = {};
        data = {
            UserName: props.username,
            SK: props.sessionKey,
            AccountId: props.accountId,
            TemplateId: props.templateId,
            Content: formControls,
            Lists: JSON.stringify(Object.values(getGridRows(formControls))),
            Path: props.siteSelected,
            DraftId: props.draftId,
            TargetCulture: "en-US",
            Autoprovision: true,
            DocumentId: documentId,
            TemplateVersion: props.templateVersion,
            TemplateXML: templateXML,
            OnBehalfUser: props.onBehalf,
            DocumentonlyPrimaryId: props.documentId,
            Rules: getImageValues(formControls),
            NotifyClient: props.notifyClient ?? false,
            Host: window.location.origin
        };

        if (props.isCampaign !== undefined && props.isCampaign) {
            data.accessCode = props.accessCode;
            data.campaignGuid = props.campaignGuid;
        }

        await HttpRequest.postWithSession(props.isSubform ? `CreateSubRecord` : "CreateDocument", { Released: Released, ...data })
            .then((data: any) => {
                const result = data.data
                if (result.Success) {
                    setCurrentFormControls(formControls)
                    if (isNaN(result.Id)) {
                        queueId.current = result.Id
                        queueTimeout(() => openQueueModal(isUpdate))
                    } else {
                        setApplying(false);
                        const description = formControls.find((item) => item.Key === "_shortcutReference")!.Value;
                        if (props.setDocumentDescription !== undefined) {
                            props.setDocumentDescription(description);
                        }
                        props.toggleCustomForm(false);
                        props.update(result.Id);
                    }

                } else {
                    setSubmitDisable(false)
                    setSpinnerMessage(
                        t("messages.error", {
                            ns: "wizard",
                            Message: result.ExceptionMessage,
                        })
                    );
                }
            })
            .catch((error: any) => {
                setSubmitDisable(false)
                setSpinnerMessage(
                    t("messages.error", {
                        ns: "wizard",
                        Message: error.toString(),
                    })
                )
            })
    }

    const closeDraftModal = () => {
        if (props.draftId > -1 && props.isCampaign === undefined) {
            props.loadDocumentDrafts?.();
            props.closeDraftModal?.();
        }
    }

    const queueTimeout = useCallback((callback: Function) => {
        isQueue.current = true
        setTimeout(() => {
            if (isQueue.current) {
                isQueue.current = false
                callback()
            }
        }, 10000);
    }, [isQueue])

    useEffect(() => {
        let message: any
        if (signalR.CreateSuccessMessage) {
            message = JSON.parse(signalR.CreateSuccessMessage)
        }

        if (isQueue.current && message.QueueId === queueId.current) {
            setApplying(false);
            const description = currentFormControls.find((item) => item.Key === "_shortcutReference")!.Value;
            if (props.setDocumentDescription !== undefined) {
                props.setDocumentDescription(description);
            }
            props.toggleCustomForm(false);
            props.update(message.Id);
            closeDraftModal();
            isQueue.current = false
            queueId.current = ""
        }
    }, [signalR.CreateSuccessMessage])

    useEffect(() => {
        let message: any
        if (signalR.UpdateSuccessMessage) {
            message = JSON.parse(signalR.UpdateSuccessMessage)
        }

        if (isQueue.current && message.QueueId === queueId.current) {
            setApplying(false);
            const description = currentFormControls.find((item) => item.Key === "_shortcutReference")!.Value;
            if (props.setDocumentDescription !== undefined) {
                props.setDocumentDescription(description);
            }
            props.toggleCustomForm(false);
            props.update(message.Id);
            isQueue.current = false
            queueId.current = ""
        }
    }, [signalR.UpdateSuccessMessage])

    useEffect(() => {
        let message: any
        if (signalR.CreateFailedMessage) {
            message = JSON.parse(signalR.CreateFailedMessage)
        }

        if (isQueue.current && message.QueueId === queueId.current) {
            openQueueModal(false, true)
            closeDraftModal();
            isQueue.current = false
            queueId.current = ""
        }
    }, [signalR.CreateFailedMessage])

    useEffect(() => {
        let message: any
        if (signalR.UpdateFailedMessage) {
            message = JSON.parse(signalR.UpdateFailedMessage)
        }

        if (isQueue.current && message.QueueId === queueId.current) {
            openQueueModal(true, true)
            isQueue.current = false
            queueId.current = ""
        }
    }, [signalR.UpdateFailedMessage])

    const getAudienceUsers = async (AudienceMasterId: string, documentId: string) => {
        const reqAudienceUsers: IDocumentOnlyAudienceRequest = {
            UserName: props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: Helper.getSiteSelected(),
            AudienceMasterId,
            FromRegistration: false
        };

        const responseAudience = await Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(reqAudienceUsers).then(
            (data: IDocumentOnlyAudienceDetail[]) => {
                return data.map(item => item.UserName?.trim().toLowerCase());
            }
        );

        const reqIntersect: IAudienceIntersect = {
            UserName: props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: documentId,
            ApiKey: "-1",
            Autoprovision: true,
            Path: Helper.getSiteSelected()
        }
        const responseIntersect = await Helper.getAudienceIntersect(reqIntersect).then((data: any[]): string[] => {
            return data.map(item => item.UserName?.trim().toLowerCase());
        })

        const allEmails = [...responseAudience, ...responseIntersect]
        const uniqueEmails = Array.from(new Set(allEmails));

        return uniqueEmails.join(",");
    };


    const _fetchData = async (props: IUseFetchProps) => {
        const { requestList } = props;
        const headers = await HttpRequest.getAxiosHeaders({ 'Content-Type': 'application/json' })
        const requests = requestList.map((request) =>

            HttpRequest.getWithSession(request.url, request.params)
            // axios.get(request.url, {
            //     method: "GET",
            //     headers: headers,
            //     params: request.params
            // })
        )
        axios.all(requests).then((resp) => {
            setApplicationResponse(resp.map((item: any) => item.data))
        }).catch((err) => {
            setApplicationResponse(undefined)
        }).finally(() => {
            setLoadingApplication(false)
        })
    }


    const getTemplateInfo = async () => {

        setApplicationResponse(undefined)
        setLoadingApplication(true)

        const requests = (): IUseFetchProps => {
            let requests: IUseFetchProps = {
                requestList: [{
                    url: "getDocumentOnlyTemplateID",
                    params: {
                        apiKey: -1,
                        accountId: props.accountId,
                        SK: props.sessionKey,
                        userName: props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername(),
                        path: sessionStorage.getItem("site"),
                        templateId: props.templateId,
                        autoprovision: true,
                        ignoreDocument: true,
                        primaryDocumentIdForSecondaryPrePopulation: props.isprimaryDocumentIdForSecondary ? props.documentId : "-1",
                        isMobile: isMobile,
                    },
                }]
            }
            if (props.data !== null && props.data !== "" && props.data !== undefined) {
                requests.requestList.push({
                    url: "GetTemplateVersionLayout",
                    params: {
                        accountId: props.accountId,
                        ApiKey: "-1",
                        SK: props.sessionKey,
                        userName: props.username,
                        path: sessionStorage.getItem("site"),
                        templateId: props.templateId,
                        TemplateVersion: props.templateVersion,
                        Description: "",
                        TargetCulture: "",
                        RefDocumentId: "",
                        Autoprovision: true,
                        isMobile: isMobile,
                    },
                });
            }
            if (props.isSubform) {
                requests.requestList.push({
                    url: 'GetDocument',
                    params: {
                        accountId: props.accountId,
                        ApiKey: "-1",
                        SK: props.sessionKey,
                        userName: props.username,
                        path: props.siteSelected,
                        Autoprovision: true,
                        DocumentId: "-1",
                        Id: props.documentId,
                        IsPreview: false,
                        IsDownload: true,
                        IsDocx: true
                    }
                });
            }
            return requests;
        };
        await _fetchData(requests())
    }


    useEffect(() => {
        if (props.showCustomForm) {
            if (props.waitToLoadProfile === undefined || props.waitToLoadProfile === true) {
                setLoading(true)
                getTemplateInfo()
            }
        }

    }, [props.waitToLoadProfile, props.showCustomForm])


    useEffect(() => {
        if (props.showCustomForm) {
            if (props.waitToLoadCustomForm === undefined || props.waitToLoadCustomForm === true) {
                getTemplateInfo()
            }
        }

    }, [props.waitToLoadCustomForm, props.showCustomForm])


    useEffect(() => {

        if (props.waitToLoadCustomForm !== undefined && props.data !== null && props.data !== "" && props.data !== undefined) {
            setLoading(true)
            getTemplateInfo()
        }
    }, [props.data, props.waitToLoadCustomForm])

  
    useEffect(() => {
        if (props.showCustomForm) {
            setSpinnerMessage(t("text.spinner", { ns: "common" }));
            let _documentValues: IFormControl[] = []

            let formValues: IFormControl[] = []
            
            if (!loadingApplication && applicationResponse !== undefined && Array.isArray(applicationResponse) && applicationResponse.length > 0) {
 
                if (props.data !== null && props.data !== "" && props.data !== undefined) {

                    try {
                        _documentValues = JSON.parse(props.data) as IFormControl[]
                        formValues = _documentValues
                        setDocumentValues(_documentValues)
                    }
                    catch (error) {
                        console.log(error)
                    }
                
                    if (props.draftId > -1) setIsUpdate(false);
                    else setIsUpdate(true);
                } else {
                    setIsUpdate(false);
                }


                let _layouts: ILayout[] = [];

                if (!props.getWizardLayout) {
                    if (applicationResponse && typeof applicationResponse?.[0] !== 'string' && "FormDesignObject" in applicationResponse?.[0] && applicationResponse?.[0] !== undefined) {
                        const formDesignObject = applicationResponse![0].FormDesignObject
                        if (formDesignObject.FormRules) {
                            setFormRules(JSON.parse(formDesignObject.FormRules) as IFormAssemblyRule[])
                        }
                        _layouts = (JSON.parse(applicationResponse![0]?.FormDesignObject.Layout!) as ILayout[])
                            .filter((layout) => +layout.Page !== 0)
                            .filter((layout) => showControl(layout.AdminOnly));
                        setStateManagement(applicationResponse![0].FormDesignObject);
                        if (props.draftId > -1) {
                            setTemplateXML(props.draftBLOB);
                        }
                    } else {
                        setGlobalErrorMsg("Critical Error - Template Corrupted. Please Re-upload your original template.");
                    }
                    if (props.data !== null && props.data !== "" && props.data !== undefined && applicationResponse && applicationResponse[1] !== undefined) {
                        let formDesignObject: any = {}
                        if (applicationResponse[0].FormDesignObject) {
                            formDesignObject = applicationResponse[0].FormDesignObject
                        } else {
                            formDesignObject = JSON.parse(applicationResponse![1]?.FormDesign);
                        }

                        setStateManagement(formDesignObject);
                        _layouts = (JSON.parse(formDesignObject.Layout) as ILayout[])
                            .filter((layout) => +layout.Page !== 0)
                            .filter((layout) =>
                                showControl(layout.AdminOnly)
                            );
                        if (formDesignObject.FormRules) {
                            setFormRules(JSON.parse(formDesignObject.FormRules) as IFormAssemblyRule[])
                        }
                        setTemplateXML(applicationResponse![1]?.TemplateXML);
                    }
                } else {
                    const wizardLayout = props.getWizardLayout()
                    _layouts = [...props.wizardLayouts];
                    setStateManagement(wizardLayout);
                    setFormRules(wizardLayout?.formRules)
                    customUseForm.handleSubmit((data) => {
                        setForcePage(props.pageSelected - 1);
                        setCurrentPage(props.pageSelected);
                    })();
                }

                _layouts = getLayoutConfiguration(_layouts);
                if (props.getWizardLayout) {
                    _layouts = _layouts.map((previewLayout: ILayout) => {
                        return {
                            ...previewLayout,
                            Id: `preview__${previewLayout.Id}`,
                            Layout: {
                                ...previewLayout.Layout,
                                i: `preview__${previewLayout.Id}`,
                            },
                        };
                    });
                    setLayouts([..._layouts])
                    setOriginalLayouts(originalLayout())
                }
                if (_documentValues.length > 0) {
                    _layouts = loadDocumentValues(_layouts, _documentValues);
                }
                loadContentLibrary(_layouts)
                if (props.draftId > -1) {
                    loadDraftImagesInfo(_layouts, formValues);
                } else {
                    loadImagesInfo(_layouts, formValues);
                }
                _layouts.forEach((item) => {
                    const defaultValue = item.Validations.DefaultValue
                    const currentValue = defaultValue ?? customUseForm?.getValues(item.Id);
                    let tempCurrentValue = currentValue !== undefined ? currentValue : item.Validations.DefaultValue;
                    if (item.Validations?.DefaultValue?.length > 0 && (currentValue === undefined || currentValue === "")) {
                        tempCurrentValue = item.Validations?.DefaultValue?.trim();
                    }
                    let formatedValue = getFormatedValue(item, {
                        Key: item.Id,
                        Value: tempCurrentValue,
                    });
                    customUseForm.setValue(item.Id, getFormatedValue(item, {
                        Key: item.Id,
                        Value: tempCurrentValue,
                    }))
                })
                setLayouts([..._layouts])
                setOriginalLayouts(originalLayout())

                if (props.culture !== null && props.culture !== undefined && props.culture !== "") setGlobalDateLocale(props.culture);
                else setGlobalDateLocale(navigator.language);
                props.documentDescription === null || props.documentDescription === "" || props.documentDescription === undefined ?
                    props.defaultDescription !== null
                        ? customUseForm.setValue(
                            "_shortcutReference",
                            props.defaultDescription
                        )
                        : customUseForm.setValue("_shortcutReference", "") : customUseForm.setValue("_shortcutReference", props.documentDescription)

            }


        }
        return () => {
            const language = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : "en";
            setGlobalDateLocale(language);
        };
    }, [props.showCustomForm, loadingApplication, applicationResponse]);

    const loadContentLibrary = (_layouts: ILayout[]) => {
        const username = props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername()
        const callback = () => {
            DocumentAssemblyService.getContentLibraryOptions(props.templateId, username).then((data) => {
                const filtered = _layouts.filter(item => item.Type === InputType.RichText)
                let tempContentLibrary: { [key: string]: IContentLibraryLanguage[] } = {}
                filtered.forEach((item: ILayout) => {
                    const contenLibraryOption: IContentLibraryLanguage[] = data.find((cc) => cc.ContentControl?.trim() === item.Id?.toString().trim())?.LanguageOptions ?? []
                    tempContentLibrary[item.Id.toString()] = contenLibraryOption
                })
                contentLibrary.current = { ...tempContentLibrary }
                setContentLibraryOptions(data)
            })
        }
        callback()
    }

    const getLayoutConfiguration = (layouts: ILayout[]): ILayout[] => {
        layouts.forEach((item) => {

            // Document shared to the user who is not the Owner or Co-Owner, and the form is enabled. The content controls set with "only available to admin"  and "required field" should avoid the required validation. 
            if (docSource === 'normal' && (!props.isOwner && !props.isCoOwner)) {
                if (item.AdminOnly) {
                    item.Validations.Required = false
                }                
            }

            // Document or template shared to the user via Template link, campaign link, or update link, the user won't be considered as Owner or CoOwner. The content controls set with "only available to admin"  and "required field" should avoid the required validation.  
            if (docSource === 'link') {
                if (item.AdminOnly) {
                    item.Validations.Required = false
                }
            }
            
            item.Layout.static = false;
            if (isMobile) {
                if (item.Type === InputType.SectionHeader && item.Label.length > 22 && item.Label.length < 70) item.Layout.h = 2;
                else if (item.Type === InputType.SectionHeader && item.Label.length > 70) item.Layout.h = 3;
            }
            if (item.Children.length > 0) {
                getLayoutConfiguration(item.Children);
            }
            if (item.Type === InputType.DatePicker) {
                item.Validations.Regex =
                    datePickerFormats.find(
                        (f) => f.key === item.Validations.Regex
                    ) !== undefined
                        ? item.Validations.Regex
                        : DEFAULT_FORM_FORMAT;
                item.Validations.DefaultValue = item.Validations.TodaysDate
                    ? getTodaysISODate()
                    : getDateFromFormat(
                        item.Validations.DefaultValue,
                        item.Validations.Regex
                    );
            } else if (item.Type === InputType.Textbox) {
                item.Validations.DefaultValue =
                    item.Validations.DefaultValue != undefined &&
                        item.Validations.DefaultValue != ""
                        ? item.Validations.DefaultValue.trimEnd()
                        : item.Validations.DefaultValue;
            }

        });
        return layouts;
    };
  
    useEffect(() => {
        if (applicationInfoLoaded) {
            AttachmentService.getSettings({
                TemplateId: props.templateId,
                DocSource: docSource,
                Username: props.username != undefined && props.username != "" ? props.username : Helper.getUsername(),
                EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.username,
                IgnoreDocument: true,
                Cache: true
            }).then((data) => {
                const { locale: _locale } = getLocaleValid(data?.Culture);
                locale.current = _locale;
                if (data?.attachment?.enable && data.attachment?.attachments?.length > 0) {
                    setShowAttachmentBtn(true);
                }
                //let tempControlsToPrint = controlsToPrint;
                layouts.forEach((item) => {
                    //tempControlsToPrint[item.Id] = elementList[item.Type](item, customUseForm, true);
                    getValidations(item, customUseForm);
                });
                if (props.isSubform) {
                    props.setDefaultDescription?.(data.DefaultDescription);
                    customUseForm.setValue("_shortcutReference", data?.DefaultDescription);
                }
                //setControlsToPrint({ ...tempControlsToPrint });
                setLoading(false);
                setApplicationInfoLoaded(false);
            });

        }
    }, [applicationInfoLoaded]);

    useEffect(() => { }, [currentPage]);


    const loadImagesInfo = async (_layouts: ILayout[], formValues?:IFormControl[]) => {
        const request = {
            UserName: props.username,
            DocumentId: props.documentId,
        }

        await HttpRequest.getWithSession('GetDocumentImages', request)
            .then(async (response: any) => {
                await setUpApplicationInfo(_layouts, response.data, formValues);
            })
            .catch((error) => {
            })
            .finally(() => {
            })
    };

    const loadDraftImagesInfo = async (_layouts: ILayout[], formValues?:IFormControl[] , draftId?: number) => {

        const request = {
            UserName: props.username,
            DraftId: draftId ?? props.draftId,
        }

        await HttpRequest.getWithSession('GetDocumentDraftImages', request)
            .then(async (response: any) => {
                await setUpApplicationInfo(_layouts, response.data, formValues);
            })
            .catch((error) => {

            })
            .finally(() => {
            })
    };

    const getLayoutFontColor = (fontColor: string) => {
        if (fontColor === null || fontColor === "") {
            return "#000";
        } else {
            return fontColor;
        }
    };

    const getLayoutJustifyContent = (align: string) => {
        if (align === null || align === "") {
            return "left";
        } else {
            return align;
        }
    };

    const getLayoutFontSize = (fontSize: number, type: InputType) => {
        if (fontSize === null || fontSize === 0) {
            switch (type) {
                case InputType.SectionHeader: {
                    return 18;
                }
                case InputType.Paragraph: {
                    return 14;
                }
                default:
                    return 12;
            }
        } else {
            return fontSize;
        }
    };

    const getFontWeight = (isBold: boolean) => {
        if (isBold === undefined || isBold === null || isBold === false) {
            return "normal";
        } else if (isBold) {
            return "bold";
        }

        return "normal";
    };

    const getFontStyle = (isItalic: boolean) => {
        if (isItalic === undefined || isItalic === null || isItalic === false) {
            return "normal";
        } else if (isItalic) {
            return "italic";
        }

        return "normal";
    };

    const getTextDecoration = (isUnderlined: boolean) => {
        if (isUnderlined === undefined || isUnderlined === null || isUnderlined === false) {
            return "none";
        } else if (isUnderlined) {
            return "underline";
        }

        return "none";
    };

    const setUpApplicationInfo = async (_layouts: ILayout[], imagesInfo: IDocumentImageResponse, formValues?:IFormControl[]) => {
        let integrations: Integration[] = [];
        _layouts.forEach((layout) => {
            if (shouldIGetTheIntegration(layout)) {
                integrations.push(layout.Integration!);
            }
        });
        integrations = integrations.filter((value, index, self) => self.map(e => e.Id).indexOf(value.Id) === index);
        const tempIntegrationDropDownLists = integrationDropDownLists;
        const customFormCCs = getCustomFormContentControls();
        let customFormCCsFormatted = customFormCCs.map((cc) => {
            return {
                ContentControl: cc.Key,
                Value: cc.Value,
            };
        });
        customFormCCs.forEach((cc) => {
            if (cc.CheckedValues !== undefined && cc.CheckedValues.length > 0) {
                const dropdownsValues = cc.CheckedValues.map((values, index) => {
                    return {
                        ContentControl: `${cc.Key}-value${index}`,
                        Value: JSON.stringify([values]),
                    }
                })
                customFormCCsFormatted = [...customFormCCsFormatted, ...dropdownsValues]
            }
        })
        const ExternalControls = [
            ...customFormCCsFormatted,
        ];
        const integrationRequests = integrations.map(
            async (integration) => {

                // if (integration.EventId !== undefined && integration.EventId !== null && integration.EventId > 0) {
                //     return OutboundService.ExecuteEventPost({
                //         EventId: integration.EventId,
                //         TemplateId: Number(props.templateId),
                //         ExternalControls,
                //         Username: props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername(),
                //         DocumentId: -1,
                //         Async: false
                //     })
                // }
                return OutboundService.ExecuteIntegration({
                    IntegrationId: integration.Id,
                    TemplateId: Number(props.templateId),
                    ExternalControls,
                    Username: props.username !== undefined && props.username !== "" ? props.username : Helper.getUsername(),
                });
            }
        );

        const integrationResponse = await Promise.all(integrationRequests);
        integrationResponse.forEach((value, index) => {
            if (value.OutputMap !== undefined) {
                if (value.ErrorMessage === null) {
                    try {
                        const { OutputMap } = value;
                        const OutputList = OutputMap.find((x: any) => x.TargetField === "dropdown");
                        const TargetValue = JSON.parse(OutputList.TargetValue);
                        tempIntegrationDropDownLists[integrations[index].Id.toString()] = TargetValue as IDropdownOption[];

                        setIntegrationDropDownLists(tempIntegrationDropDownLists);
                    } catch (error) {
                        console.log("Error when loading integrations.");
                        setGlobalErrorMsg("Error when loading integrations.");
                        console.log(error);
                    }
                } else {
                    console.log("Error when loading integrations.");
                    setGlobalErrorMsg("Error when loading integrations.");
                }
            }
            else {
                const { data } = value;
                if (data.Success !== false) {
                    try {
                        const { Data } = data;
                        const OutputMap = JSON.parse(Data.OutputMap);
                        const OutputList = OutputMap.find((x: any) => x.TargetField === "dropdown");
                        const TargetValue = JSON.parse(OutputList.TargetValue);
                        tempIntegrationDropDownLists[integrations[index].Id.toString()] = TargetValue as IDropdownOption[];

                        setIntegrationDropDownLists(tempIntegrationDropDownLists);
                    } catch (error) {
                        console.log("Error when loading integrations.");
                        setGlobalErrorMsg("Error when loading integrations.");
                        console.log(error);
                    }
                } else {
                    console.log("Error when loading integrations.");
                    setGlobalErrorMsg("Error when loading integrations.");
                }
            }

        });

        let listIds: number[] = [];
        _layouts.forEach((layout) => {
            if (shouldIGetTheList(layout)) {
                if (layout.Children.length > 0) {
                    layout.Children.forEach((item) => {
                        listIds.push(item.ListId);
                    });
                }
                listIds.push(layout.ListId);
            }
        });
        listIds = listIds.filter((value, index, self) => self.indexOf(value) === index);
        const requests = listIds.map(async (listId) => {
            return ListService.GetListsDetails({ DocumentOnlyListMasterId: String(listId) })
        });

        let tempCustomDropDownLists = customDropDownLists;
        const responseListsDetails = await Promise.all(requests);

        responseListsDetails.forEach((responseListDetail) => {
            if (responseListDetail.length === 0) {
                return;
            }
            tempCustomDropDownLists[responseListDetail[0].DocumentOnlyListMasterId] = responseListDetail.map<IDropdownOption>((item) => {
                return {
                    key: item.Key.toString(),
                    text: item.Value,
                };
            });
            setCustomDropDownLists(tempCustomDropDownLists);
        });

        //Load Grid List
        let tempGridListRows: { [key: string]: IList } = {};
        // const _documentValues = documentValues
         const _documentValues = formValues !== undefined? formValues : documentValues

        _layouts
            .filter((item) => item.Type === InputType.GridList)
            .forEach((item) => {
                const defaultValue = item.Validations.DefaultValue !== "" ? item.Validations.DefaultValue : "[]";
                let currentDocumentValue: IFormControl = props.isSubform ? (JSON.parse(defaultValue) as IFormControl[])[0] : _documentValues.find((info) => info.Key === item.Id)!;
                let currentList: IList = {
                    IdList: item.Id,
                    Rows: [],
                };
                if (currentDocumentValue !== undefined) {
                    currentList = JSON.parse(currentDocumentValue.Value) as IList;
                    const rows: IRow[] = currentList.Rows.map((row) => {
                        const cells: ICell[] = row.Cells.map((cell, index) => {
                            const field: ILayout | undefined = item.Children.find(item => item.Id === cell.Id)
                            if (field !== undefined && field.Type === InputType.DatePicker) {
                                const dateFormated = getDateFromFormat(
                                    cell.Value,
                                    field.Validations.Regex
                                );

                                return {
                                    Id: cell.Id,
                                    Type: field.Type.toString(),
                                    Value: dateFormated,
                                } as ICell;
                            }
                            return cell;
                        });
                        item.Children.map((child) => child.Id === item.Id);
                        row.Cells = cells;
                        return row;
                    });
                    currentList.Rows = rows;
                }
                tempGridListRows[item.Id] = currentList;
            });
  
        if(!integrationTriggered) {
            setGridListRows({ ...tempGridListRows });    
        }

        //Load Images
        let tempImages: { [key: string]: IRule } = {};
        _layouts
            .filter((item) => item.Type === InputType.Image)
            .forEach((item) => {
                let tempCCValue = getImageControlValue(item, imagesInfo);
                let rule: IRule = {
                    RuleAction: RuleActionType.REPLACE,
                    CCName: item.Id,
                    CCType: InputType.Image,
                    CCVaue: tempCCValue,
                    ImageName: tempCCValue.length > 0 ? t("text.imageLoaded", { ns: "wizard" }) : item.TextParagraph,
                };
                tempImages[item.Id] = rule;
            });
        setImageValues(tempImages);
        if (firstLoading) {
            setImageValuesDefault({ ...tempImages })
            setFirstLoading(false)
        }
        //Application Loaded
        setApplicationInfoLoaded(true);
    };

    const getImageControlValue = (item: ILayout, imagesInfo: IDocumentImageResponse): string => {
        if (imagesInfo.Rules !== null && imagesInfo.Rules !== undefined && firstLoading) {

            const tempImage = imagesInfo.Rules.Rule.find((image) => image.Ccname === item.Id);
            if (tempImage !== undefined) {
                return tempImage.Image;
            }
        }
        if (item.Validations.DefaultValue !== "") {
            return item.Validations.DefaultValue;
        }
        return "";
    };

    const shouldIGetTheList = (layout: ILayout): boolean => {
        if (layout.Children.length > 0) {
            return layout.Children.filter((f) => f.ListId > 0 && (InputType.CheckBoxList === f.Type || InputType.DropDownList === f.Type)).length > 0 ? true : false;
        } else {
            return layout.ListId > 0 && (InputType.CheckBoxList === layout.Type || InputType.DropDownList === layout.Type);
        }
    };

    const shouldIGetTheIntegration = (layout: ILayout): boolean => {
        if (layout.Integration !== undefined && layout.Integration !== null) {
            return layout.Integration.Id > 0 && (InputType.CheckBoxList === layout.Type || InputType.DropDownList === layout.Type);
        } else {
            return false;
        }
    };
    useEffect(() => {
        if (!loading) {
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollMovil")) as Element;
            if (element !== null) {
                element.children[1].classList.add("scrollVisible");
                element.children[1].classList.add("scrollVisibleXHidden");
            }
        }
    }, [loading]);

    const getContentLibraryOptions = (contentControl: string): IContentLibraryLanguage[] => {
        return contentLibrary.current[contentControl]
    }

    const getDefaultValue = (layout: ILayout, form: UseFormReturn<any, any> | undefined, defaultValue?: string): any => {
        switch (layout.Type) {
            case InputType.RichText:
                let richValue = "";
                if (props.isSubform) {
                    richValue = layout.Validations.DefaultValue;
                } else {
                    let documentRichTextValue = documentValues.find(
                        (info) => info.Key === layout.Id
                    );
                    richValue =
                        documentRichTextValue !== undefined
                            ? documentRichTextValue.Value
                            : layout.Validations.DefaultValue !== ""
                                ? layout.Validations.DefaultValue
                                : "";
                }
                return richValue;
            default:
                const currentValue = defaultValue ?? form?.getValues(layout.Id);
                let tempCurrentValue = currentValue !== undefined ? currentValue : layout.Validations.DefaultValue;
                if (typeof tempCurrentValue !== 'string' && tempCurrentValue?.length > 0) {
                    tempCurrentValue = tempCurrentValue.join(',')
                }
                if (layout.Validations?.DefaultValue?.length > 0 && (currentValue === undefined || currentValue === "")) {
                    tempCurrentValue = layout.Validations?.DefaultValue?.trim();
                }
                let formatedValue = getFormatedValue(layout, {
                    Key: layout.Id,
                    Value: tempCurrentValue,
                });

                //form.setValue(layout.Id, formatedValue);

                let gridColumns = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children), []);
                if (gridColumns.find((l) => l.Id === layout.Id) !== undefined) {
                    if (formatedValue === undefined) return undefined;
                    else if (layout.Type === InputType.DropDownList) return formatedValue.key;
                    else return formatedValue;
                }

                if (layout.Type === InputType.Currency) {

                    if (Number.isNaN(Number(tempCurrentValue))) {
                        tempCurrentValue = "0"
                    }

                    const _currency = layout.Validations?.Currency

                    if (_currency === undefined || _currency === null) {
                        var resultCurrency = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                        })
                            .formatToParts(tempCurrentValue)
                            .filter((p) => p.type != "currency" && p.type != "group") // removes the currency part
                            .reduce((s, p) => s + p.value, "") // joins the remaining values
                            .trim();

                        return resultCurrency.toString();
                    }
                }
       
                return tempCurrentValue;
        }
    };
    const loadDocumentValues = (layouts: ILayout[], _documentValues: IFormControl[], overrideProfile: boolean = false): ILayout[] => {
        layouts.forEach((item) => {
            const hasDisplayFunction = item.Validations.DefaultValue?.includes('DisplayName') ? item.Validations.DefaultValue : undefined
            let valueToFormat = _documentValues.find(
                (info) => info.Key === item.Id
            );

            if (item.manualTag) {
                valueToFormat = _documentValues.find(
                    (info) => info.Key.toLowerCase() === item.Id.toLowerCase()
                );
            }
            if (valueToFormat === undefined && item.Type !== InputType.LineBreak) {
                item.Validations.DefaultValue = "";
            }

            if (valueToFormat !== undefined && item.Type !== InputType.LineBreak) {
                item.Validations.DefaultValue = valueToFormat.Value.trimEnd();
            }
            if (hasDisplayFunction && overrideProfile) {
                item.Validations.DefaultValue = getTextboxValue(hasDisplayFunction)
            }
        });
        return layouts;
    };

    const getFormatedValue = (layout: ILayout, valueToFormat: IFormControl): any => {
        switch (layout.Type) {
            case InputType.DropDownList: {
                return valueToFormat.Value.length > 0
                    ? {
                        key:
                            layout.Integration !== undefined &&
                                layout.Integration !== null
                                ? integrationDropDownLists[
                                    layout.Integration.Id.toString()
                                ]
                                    ?.find(
                                        (item) =>
                                            item.text ===
                                            valueToFormat.Value
                                    )
                                    ?.key.toString()
                                : +layout.ListId > 0
                                    ? customDropDownLists[layout.ListId]
                                        ?.find(
                                            (item) =>
                                                item.text ===
                                                valueToFormat.Value
                                        )
                                        ?.key.toString()
                                    : valueToFormat?.Value,
                        text: valueToFormat?.Value,
                    }
                    : undefined;
            }
            case InputType.CheckBoxList: {
                let dataSubform = [] as IFormControl[];
                const isDraft = props.draftId !== undefined && props.draftId > 0;
                if (props.isSubform && !isDraft) {
                    if (applicationResponse![1].Data) {
                        dataSubform = JSON.parse(applicationResponse![1].Data) as IFormControl[]
                    } else {
                        dataSubform = []
                    }
                }
                if (props.isSubform && isDraft) {
                    dataSubform = JSON.parse(applicationResponse![2].Data) as IFormControl[]
                }
                let contentControl = documentValues.find(
                    (info) => info.Key === layout.Id
                );
                if (props.isSubform) {
                    contentControl = dataSubform.find(
                        (info) => info.Key === layout.Id
                    );
                }
                const getCheckedValues = (CheckedValues: ICheckedValues[]) => {
                    return CheckedValues.map((checkedValue) => {
                        return {
                            key:
                                layout.Integration !== undefined &&
                                    layout.Integration !== null
                                    ? integrationDropDownLists[layout.Integration.Id.toString()]
                                        ?.find((item) => item.text === checkedValue.Text)
                                        ?.key.toString()
                                    : +layout.ListId > 0
                                        ? customDropDownLists[layout.ListId]?.find((item) => item.text === checkedValue.Text)
                                            ?.key.toString()
                                        : checkedValue.Key,
                            text: checkedValue.Text,
                            selected: true,
                        };
                    })
                }
                const formatValue = () => {

                }
                return contentControl?.CheckedValues !== undefined && contentControl?.CheckedValues !== null ?
                    getCheckedValues(contentControl?.CheckedValues)
                    : valueToFormat.Value.length > 0
                        ? valueToFormat.Value.split(",").map((text) => {
                            return {
                                key:
                                    layout.Integration !== undefined &&
                                        layout.Integration !== null
                                        ? integrationDropDownLists[
                                            layout.Integration.Id.toString()
                                        ]
                                            ?.find((item) => item.text === text)
                                            ?.key.toString()
                                        : +layout.ListId > 0
                                            ? customDropDownLists[layout.ListId]
                                                ?.find((item) => item.text === text)
                                                ?.key.toString()
                                            : text,
                                text: text,
                                selected: true,
                            };
                        })
                        : undefined;
            }
            case InputType.Textbox: {
                return valueToFormat.Value != undefined &&
                    valueToFormat.Value.length > 0
                    ? getTextboxValue(valueToFormat.Value)
                    : undefined;
            }
            case InputType.TextArea: {
                return cleanHtml(valueToFormat.Value);
            }

            case InputType.Currency: {
                return cleanHtml(valueToFormat.Value);
            }

            case InputType.CheckBox: {
                if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === layout.Id) !== undefined) {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        return "true";
                    } else {
                        return "";
                    }
                } else {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        if (layout.Validations?.DefaultValue.toLowerCase() === "true") {
                            return "true"
                        } else {
                            return "false"
                        }
                    } else {
                        return "false"
                    }

                }

            }
            default: {
                return valueToFormat.Value;
            }
        }
    };


    const showControl = (adminOnly: boolean): boolean => {

        return true
        /*
        if(adminOnly && docSource === 'link') return false
        
        if (adminOnly) {
            return props.isAdmin != undefined
                ? props.isAdmin
                : props.isOwner || props.isCoOwner
                    ? true
                    : false;
        } else {
            return true;
        }
        */
    };
    const getIsAdmin = () => {
        return props.isAdmin != undefined
            ? props.isAdmin
            : props.isOwner || props.isCoOwner
                ? true
                : false;
    }


    const onRenderLabel = (props: any, l: ILayout, labelVisible: boolean) => {
        return (
            <div style={{ display: labelVisible ? "flow-root" : "none" }}>
                <label
                    htmlFor={l.Id}
                    style={{
                        float: "left",
                        fontSize: 14,
                        fontFamily: "Segoe UI Web (West European)",
                        fontWeight: "normal",
                        width: "80%"
                    }}
                >
                    {props.label}
                </label>
                {props.required || props.isRequired ? (
                    <span title={t("label.required", { ns: "wizard" })} style={{ fontWeight: "normal", float: "right", color: "#973434" }}>
                        *
                    </span>
                ) : null}
                {l.TooltipUrl === "" ||
                    l.TooltipUrl === undefined ||
                    l.TooltipUrl === null ? null : (
                        <IconButton
                            onClick={() => {
                                window.open(l.TooltipUrl, "_blank");
                            }}
                            id={"icon" + props.id}
                            styles={{
                                root: {
                                    float: "right",
                                    marginBottom: -3,
                                    height: "100%",
                                },
                            }}
                            iconProps={{ iconName: "Info" }}
                            title={l.TooltipUrl}
                            ariaLabel="Info"
                        />
                    )}
            </div>
        );
    };

    const displayIntegration = (layout: ILayout) => {
        if (layout.Integration?.DisplayType === DisplayType.Always || (layout.Integration?.DisplayType === DisplayType.OnCreate && (props.data === null || props.data === "" || props.data === undefined)) || (layout.Integration?.DisplayType === DisplayType.OnUpdate && props.data !== null && props.data !== "" && props.data !== undefined)) {
            return true;
        } else {
            return false;
        }
    };





    const preview = () => {
        var formData: IFormControl[] = [];
        formData = getFormControls(customUseForm.getValues());
        var desc = formData.filter((x) => x.Key === "_shortcutReference");
        var shortcutRef = "";

        if (desc !== null && desc.length > 0) {
            if (isUpdate && props.templateDescription !== undefined && props.templateDescription !== "") {
                desc[0].Value = props.templateDescription;
            }

            if (desc[0].Value.trim().length === 0) {
                desc[0].Value = t("text.applicationUnamed", { ns: "wizard" });
            }
            shortcutRef = desc[0].Value;
        }

        setLayoutLoaded(false);
        setShowPreview(true);
        setModalTitle(
            t("title.modalWithDocumentName", {
                ns: "preview",
                Name: shortcutRef,
            })
        );
        setSpinnerText(t("text.spinner", { ns: "common" }));

        const loadPreview = async () => {
            const { Released } = stateManagement;
            const req: ICreateDocumentRequest = {
                UserName: props.username,
                SK: props.sessionKey,
                AccountId: props.accountId,
                TemplateId: props.templateId,
                Content: formData,
                Lists: JSON.stringify(Object.values(getGridRows(formData))),
                Path: props.siteSelected,
                TargetCulture: "en-US",
                Autoprovision: true,
                DocumentId: "-1",
                TemplateVersion: props.templateVersion,
                TemplateXML: templateXML,
                onBehalf: props.onBehalf,
                DocumentonlyPrimaryId: props.documentId,
                Rules: getImageValues(formData)
            };

            var res = await Helper.getDocumentPreviewBeforeSubmit(req, Released);
            if (res.Success) {
                setPreviewURL(res.UrlPath);
                setPreviewMime(res.mimeType);
                setLayoutLoaded(true);
            } else {
                setShowPreview(false);
            }
        };
        loadPreview();
    };

    const hidePreview = () => {
        setShowPreview(false);
    };

    // Tokens definition
    const mobileStackTokens: IStackTokens = {
        childrenGap: 50 + " " + 20,
        padding: `20px 20px 20px 20px`,
    };

    const getRefreshedGridLayoutHtmlElements = (controls: ILayout[]): JSX.Element => {
        return (
            <ResponsiveReactGridLayout className="form-layout"
                layouts={{
                    lg: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                    md: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                    sm: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                    xs: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                    xxs: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                }}
                cols={{ lg: 4, md: 4, sm: 4, xs: 1, xxs: 1 }}
                rowHeight={35}
                useCSSTransforms={false}
                isResizable={false}
                isDraggable={false}
                compactType={null}
            >
                {controls
                    .filter((item) => +item.Page === currentPage)
                    .sort((a, b) => (a.Layout.y - b.Layout.y) || (a.Layout.x - b.Layout.x))
                    .map((control) => {

                        return (
                            <div className={InputType[control.Type]} style={{
                                gridColumn: `${control.Layout.x + 1} / span ${control.Layout.w}`,
                                gridRow: `${control.Layout.y + 1} / span ${control.Layout.h}`,
                            }} key={control.Id}>
                                {controlsToPrint[control.Id]}
                            </div>
                        );
                    })}
            </ResponsiveReactGridLayout>
        );
    };

    const createDraft = (draftId: number) => {
        setSpinnerMessage(t("text.spinnerSaving", { ns: "common" }));
        let contentControls = getFormControls(customUseForm.getValues());

        DraftServices.CUDDocumentDraft({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Content: contentControls,
            Description: contentControls.find((item) => item.Key === "_shortcutReference")!.Value,
            EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.username,
            Id: draftId,
            ParentId: props.isSubform ? (props.parentId === undefined ? +props.documentId : props.parentId) : props.parentId,
            Operation: draftId === -1 ? OperationType.create : OperationType.update,
            Path: Helper.getSiteSelected(),
            SK: Helper.getSessionKey(),
            TemplateId: +props.templateId,
            TemplateVersion: -1,
            TemplateXML: templateXML,
            UserName: props.username != undefined && props.username != "" ? props.username : Helper.getUsername(),
            Lists: JSON.stringify(Object.values(getGridRows(contentControls))),
            CampaignUserGUID: props.campaignGuid !== undefined ? props.campaignGuid : "",
            Rules: getImageValues(contentControls),
        }).then((res) => {
            if (props.isCampaign === undefined && props.sendlink === undefined) {
                if (props.draftId > 0) props.loadDocumentDrafts?.();
            }
            if ((props.isCampaign !== undefined && props.isCampaign) || props.sendlink) {
                //props.createUpdateDraft();
                DraftServices.GetDocumentDrafts({
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.username,
                    Id: Number(res.Id),
                    Path: Helper.getSiteSelected(),
                    SK: Helper.getSessionKey(),
                    UserName: props.username != undefined && props.username != "" ? props.username : Helper.getUsername(),
                }).then(res2 => {
                    setTemplateXML(res2[0].BLOB!)
                    setLinkDraftId(Number(res.Id))
                    setSuccessMessage("Draft saved correctly.")
                })
            }
            !props.sendlink && props.toggleCustomForm(false);
            setSpinnerMessage(t("text.spinner", { ns: "common" }));
            setApplying(false);
        });
    };

    const createDraftAndAttachment = (callback: (Id: string, targetUser: string, callback?: Function) => void) => {
        let contentControls = getFormControls(customUseForm.getValues());
        DraftServices.CUDDocumentDraft({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Content: contentControls,
            Description: contentControls.find((item) => item.Key === "_shortcutReference")!.Value,
            EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.username,
            Id: -1,
            ParentId: props.isSubform ? (props.parentId === undefined ? +props.documentId : props.parentId) : props.parentId,
            Operation: OperationType.create,
            Path: Helper.getSiteSelected(),
            SK: Helper.getSessionKey(),
            TemplateId: +props.templateId,
            TemplateVersion: -1,
            TemplateXML: templateXML,
            UserName: props.username != undefined && props.username != "" ? props.username : Helper.getUsername(),
            Lists: JSON.stringify(Object.values(getGridRows(contentControls))),
            CampaignUserGUID: props.campaignGuid !== undefined ? props.campaignGuid : "",
            Rules: getImageValues(contentControls),
        }).then((data) => {
            if (data.Success) {
                callback(data.Id, props.targetUser, () => {
                    DraftServices.GetDocumentDrafts({
                        AccountId: Helper.getDefaultAccount(),
                        ApiKey: "-1",
                        Autoprovision: true,
                        EndUserName: props.onBehalf !== undefined ? props.onBehalf : props.username,
                        Id: data.Id,
                        Path: Helper.getSiteSelected(),
                        SK: Helper.getSessionKey(),
                        UserName: props.username != undefined && props.username != "" ? props.username : Helper.getUsername(),
                        DocSource: docSource,
                    }).then(([draft]) => {
                        setTemplateXML(draft.BLOB!);
                        props.setDraftId?.(draft.Id);
                    });
                });
            }
        });
    };

    const integrationReload = (data: IFormControl[], draftId?: number, draftDescription?: string, overrideProfile: boolean = false) => {
        integrationTriggered = true
        setLoading(true);
        customUseForm.reset();
        const description = data.find((x) => x.Key === "_shortcutReference");
        customUseForm.setValue("_shortcutReference", draftDescription ?? description?.Value);
        setControlsToPrint({});
        let _layouts: ILayout[] = [];
        if (applicationResponse![0] !== "" && "FormDesignObject" in applicationResponse![0]) {
            _layouts = (JSON.parse(applicationResponse![0]?.FormDesignObject.Layout!) as ILayout[])
                .filter((layout) => +layout.Page !== 0)
                .filter((layout) => showControl(layout.AdminOnly));
        } else {
            if (applicationResponse![0].FormDesignObject === "" || applicationResponse![0].FormDesignObject === null || applicationResponse![0].FormDesignObject === undefined) {
                setGlobalErrorMsg("Critical Error - Template Corrupted. Please Re-upload your original template.");
            } else {
                _layouts = (
                    JSON.parse(JSON.parse(applicationResponse![1]?.FormDesign).Layout) as ILayout[])
                    .filter((layout) => +layout.Page !== 0)
                    .filter((layout) => showControl(layout.AdminOnly));
            }
        }
        _layouts = getLayoutConfiguration(_layouts);
        _layouts = loadDocumentValues(_layouts, data, overrideProfile);
        if (props.draftId > -1) {
            loadDraftImagesInfo(_layouts);
        } else if (draftId !== null && draftId !== undefined && draftId > 0) {
            loadDraftImagesInfo(_layouts, undefined, draftId);
        } else {
            loadImagesInfo(_layouts);
        }
        _layouts.forEach((item) => {
            if (item.Type === InputType.DropDownList && item.Validations.DefaultValue.trim().length === 0) {
                customUseForm.setValue(item.Id, undefined)
            }
            else customUseForm.setValue(item.Id, item.Validations.DefaultValue)
        })
        setLayouts([..._layouts]);
        // setOriginalLayouts(originalLayout())
        setFormLoading();
    };

    const reloadProfile = () => {

        integrationReload(getFormControls(customUseForm.getValues()), undefined, undefined, true)
    }
    const setFormLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const getCustomFormContentControls = () => {
        return getFormControls(customUseForm.getValues());
    };

    const handleCloseModal = () => {
        props.toggleCustomForm(false);
        if (props.sendlink === true) {
            props.toggleClose(false);
        } else {
            props.toggleCustomForm(false);
        }
        setGlobalDateLocale(navigator.language);
        setApplying(false);
        customUseForm.reset(
            {},
            {
                keepDirty: false,
                keepErrors: false,
                keepIsValid: false,
            },
        );
        customUseForm.clearErrors();
        if (props.draftId !== undefined && props.draftId !== -1) {
            props.closeDraftModal?.();
        }
    };
    const handleLoadDraft = (draft: any, callback: Function) => {
        setTemplateXML(draft.BLOB!);
        setLinkDraftId(draft.Id);
        props.setDraftId?.(draft.Id)

        const jsonBlob = JSON.parse(
            draft.JsonBLOB
        ) as IFormControl[];
        integrationReload(jsonBlob, draft.Id, draft.Description)
        callback()
    }

    const openQueueModal = useCallback((isUpdate: boolean, isError: boolean = false) => {
        setApplying(false);
        props.toggleCustomForm(false);
        props.toggleQueueModal(true, isUpdate, isError);
        if (props.updateDocumentList) {
            props.updateDocumentList()
        }
    }, [])


    /**
     * Retrieves the original layout array from the layouts object.
     *
     * @returns {Array} The original layout array.
     */
    const originalLayout = (): Array<any> => {
        return layouts.map(item => {
            return {
                Id: item.Id, 
                Page: item.Page,
                Layout: {
                    x: item.Layout.x,
                    y: item.Layout.y,
                    h: item.Layout.h,
                    w: item.Layout.w
                },
                Validations: {
                    ReadOnly: item.Validations.ReadOnly,
                    Hide: item.Validations.Hide
                }
            }
        })
    }

    return (
        <CustomFormProvider isAdmin={showControl(props.isAdmin)} locale={locale.current} layouts={layouts} originalLayouts={originalLayouts} templateId={props.templateId} data={props.data} isSubform={props.isSubform}
            documentValues={documentValues} applicationResponse={applicationResponse} integrationDropDownLists={integrationDropDownLists}
            formRules={formRules} setFormRules={setFormRules} setLayouts={setLayouts}
            customDropDownLists={customDropDownLists} user={{ username: props.username, isOwner: props.isOwner, isCoOwner: props.isCoOwner }}>

            <ResponsiveModal isOpen={props.showCustomForm} className={docSource?.includes('link') ? "DialogBackgoundColor" : ''}
                title={isUpdate ? getRecordDescription(props.templateName, props.templateDescription) : getRecordDescriptionTemplateName(props.templateName)}
                titleId={"customFormWindow"} containerClassName={docSource?.includes('link') ? contentStyles.container : contentStyles.container2}
                navigation={
                    <FormHeader isAccessCodeValid={isAccessCodeValid} setApplying={setApplying} linkDraftId={linkDraftId} handleCloseModal={handleCloseModal}
                        isAppLinkDraft={props.isAppLinkDraft} allowDrafts={props.allowDrafts} showAttachmentBtn={showAttachmentBtn} setLoading={setLoading}
                        handleLoadDraft={handleLoadDraft} draftId={props.draftId} templateId={props.templateId} username={props.username} reloadProfile={reloadProfile}
                        isWizardPreview={props.isWizardPreview} onBehalf={props.onBehalf} setIsAccessCodeValid={props.setIsAccessCodeValid}
                        recordDescription={isUpdate ? getRecordDescription(props.templateName, props.templateDescription) : getRecordDescriptionTemplateName(props.templateName)}
                        createDraft={createDraft} preview={preview} setShowAttachment={setShowAttachment} isUpdate={false} />
                } handleClose={handleCloseModal}
                closeButtonAriaLabel={t("Common.Button.Close", { ns: "common", })}>
                <Stack>
                    {loading ? (
                        <Spinner
                            size={SpinnerSize.medium}
                            label={t("text.spinner", {
                                ns: "common",
                            })}
                            ariaLive="assertive"
                        />
                    ) : applying ? (
                        <Spinner size={SpinnerSize.medium} label={spinnerMessage} ariaLive="assertive" />
                    ) : (
                                <FormProvider {...customUseForm}>
                                    <Stack>
                                        <div className="ms-Grid">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 divBr">
                                                    {globalErrorMsg.length > 0 ? (
                                                        <StatusMessage
                                                            setShowMessage={() => { }}
                                                            status={StatusEnum.Error}
                                                        >
                                                            {globalErrorMsg}
                                                        </StatusMessage>
                                                    ) : (
                                                            <></>
                                                        )}
                                                    {errorMessage !== "" ? (
                                                        <StatusMessage
                                                            setShowMessage={() => setErrorMessage("")}
                                                            status={StatusEnum.Error}
                                                            isMultiline={true}
                                                        >
                                                            {errorMessage}
                                                        </StatusMessage>
                                                    ) : (
                                                            <></>
                                                        )}
                                                    {successMessage !== "" ? (
                                                        <StatusMessage
                                                            setShowMessage={() => setSuccessMessage("")}
                                                            status={StatusEnum.Success}
                                                            isMultiline={true}
                                                        >
                                                            {successMessage}
                                                        </StatusMessage>
                                                    ) : (
                                                            <></>
                                                        )}
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Stack horizontal
                                                        tokens={{
                                                            childrenGap: 15,
                                                            padding: `25px 0px 0px 0px`,
                                                        }}
                                                        style={{
                                                            minHeight: "60px"
                                                        }}>
                                                        <Label style={{
                                                            display: "flex",
                                                            paddingLeft: "15px",
                                                            justifyContent: "left",
                                                            fontSize: "18px",
                                                        }}>
                                                            {t("label.description", { ns: "wizard" })}
                                                        </Label>
                                                        <ControlledTextField
                                                            styles={textFieldStyle}
                                                            borderless
                                                            autoFocus
                                                            required={true}
                                                            style={{
                                                                minWidth: isMobile ? 140 : 350,
                                                            }}
                                                            control={customUseForm.control}
                                                            id="_shortcutReference"
                                                            maxLength={200}
                                                            name={"_shortcutReference"}
                                                            tabIndex={1}
                                                            placeholder={props.defaultDescription}
                                                            title={t("title.description", { ns: "wizard" })}
                                                            rules={{
                                                                required: !isUpdate
                                                                    ? t(
                                                                        "messages.errorFieldRequired",
                                                                        {
                                                                            ns: "wizard",
                                                                        }
                                                                    )
                                                                    : false,
                                                            }}
                                                        />
                                                    </Stack>
                                                </div>
                                            </div>
                                            <Separator />

                                            <div className="container-form p-lg">
                                                <LayoutWrapper isMobile={isMobile}>
                                                    <ListControls contentLibrary={contentLibraryOptions} layouts={layouts} currentPage={currentPage} integrationDropDownLists={integrationDropDownLists}
                                                        imageValues={imageValues} imageValuesDefault={imageValuesDefault} getFormControls={getFormControls} setImageValues={setImageValues}
                                                        getDefaultValue={getDefaultValue} getFormatedValue={getFormatedValue}
                                                        gridListRows={gridListRows} locale={locale} getValidations={getValidations} draftId={props.draftId}
                                                        setGridListRows={setGridListRows} integrationReload={integrationReload} applicationResponse={applicationResponse}
                                                        getCustomFormContentControls={getCustomFormContentControls} formRules={formRules}
                                                        documentValues={documentValues} isOwner={props.isOwner} isSubform={props.isSubform}
                                                        getContentLibraryOptions={getContentLibraryOptions} data={props.data} isCoOwner={props.isCoOwner}
                                                        templateId={props.templateId} username={props.username} setErrorMessage={setErrorMessage} customDropDownLists={customDropDownLists}
                                                        docSource={docSource} isAdmin={props.isAdmin} isUpdate={isUpdate}
                                                    />
                                                </LayoutWrapper>
                                            </div>
                                            <Separator theme={theme}></Separator>
                                        </div>
                                    </Stack>
                                    <>
                                        {!loading ? (
                                            <>
                                                {isAccessCodeValid &&
                                                    <FormPaginate currentPage={currentPage} forcePage={forcePage} showPagination={showPagination}
                                                        setCurrentPage={setCurrentPage} setForcePage={setForcePage}
                                                        pages={+stateManagement.Pages!} submitDisable={submitDisable} data={props.data}
                                                        handleCloseModal={handleCloseModal} onSave={onSave} isWizardPreview={props.isWizardPreview} />
                                                }
                                            </>
                                        ) : (
                                                <></>
                                            )}
                                    </>
                                </FormProvider>
                            )}
                </Stack>
            </ResponsiveModal>


            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
                docSource={docSource}
            />
            <Attachment
                showAttachment={showAttachment}
                hideAttachament={hideAttachment}
                documentId={props.isSubform ? -1 : props.documentId ?? -1}
                documentDescription={""}
                docSource={docSource}
                update={() => { }}
                documentDraftId={props.draftId}
                targetUser={props.onBehalf !== undefined ? props.onBehalf : props.targetUser}
                username={props.username != undefined && props.username != "" ? props.username : Helper.getUsername()}
                templateId={props.templateId}
                activeSubscription={props.activeSubscription}
                subscriptionStatusMessage={props.subscriptionStatusMessage}
                showRules={true}
                showAttachments={false}
                validAttachments={validAttachments}
                setValidAttachments={setValidAttachments}
                createDraftAndAttachment={createDraftAndAttachment}
            />
            {props.children}
        </CustomFormProvider>
    );
};
const LayoutWrapper: React.FC<{ isMobile: boolean }> = (props) => {
    return <>
        {isMobile ? <>{props.children}</> : <div
            className="scrollVisible"
            style={{
                overflowX: "hidden",
                maxWidth: "100%",
                height:
                    window.innerHeight > 660 ? "63vh"
                        : isMobile ? "52vh" : window.innerHeight < 600
                            ? "50vh" : "58vh",
            }}
        >
            {props.children}
        </div>}
    </>
}
export const getRecordDescription = (title: string, description: string) => {
    const _description = description ? `(${description})` : "";
    let value = isMobile ? title : `${title} ${_description}`;

    if (isMobile && value.length > 15) {
        value = value.substring(0, 12) + "...";
    }

    return value;
};
export const getRecordDescriptionTemplateName = (title: string) => {
    var value = title;
    if (isMobile && value.length > 15) {
        value = value.substring(0, 12) + "...";
    }
    return value;
};