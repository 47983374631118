import React from 'react'
import {emptyBackgroundColor, ILayout} from "../../../CustomTemplate";
import {InputType} from "../../../Validations";

import {FieldTypeValidations, ValidationType} from "../../Models/ValidationType";
import {getTheme, Icon, IconButton, Text, TooltipHost, Stack, IStackStyles} from '@fluentui/react';
import i18n from "../../../../../i18n/config"
import {datePickerFormats} from '../../../../../Helpers/DateUtils';
import {RegisterOptions, UseFormReturn} from 'react-hook-form';
import {EditorValue} from 'react-rte';
import {EnumDictionary} from '../../Models/EnumDictionatyType';
import {ActionType, TabActionType} from '../../../../../interfaces/IFormAssembly';
import {IRawStyle} from "@uifabric/merge-styles/lib/IRawStyle";

const { t } = i18n
const theme = getTheme();
const iconButtonWithoutHoverStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const requiredColor = (labelStyles?: IRawStyle | undefined):string => {
    if( labelStyles !== undefined && labelStyles !== null) {
        if(labelStyles.backgroundColor === undefined ||
            labelStyles.backgroundColor === null ||
            labelStyles.backgroundColor === "unset" ||
            labelStyles.backgroundColor === "" ||
            labelStyles.backgroundColor.trim().toLowerCase() === "#fff" || 
            labelStyles.backgroundColor.trim().toLowerCase() === "#ffff" || 
            labelStyles.backgroundColor.trim().toLowerCase() === "#ffffff") {
            return "#973434"
        }
        else {
            if (labelStyles.color === undefined) return "#000000"
            else return labelStyles.color
        }
    }
    else {
        return "#973434"
    }
}

export const onRenderLabel = (props: any, l: ILayout, labelVisible: boolean, labelStyles?: IRawStyle | undefined) => {
    
    if(l.Version === undefined || l.Version === null || l.Version <= 2 ) {
        if([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBoxList,
            InputType.CheckBox,
            InputType.Number,
            InputType.Currency,
            InputType.DatePicker,
            InputType.Image,
        ].includes(l.Type)) {
            if(labelStyles !== undefined) labelStyles.fontSize = "14px"
        }
    }   
    
    return (
        !labelVisible ?
                <></>
        :
        <>
        <Stack horizontal
               tokens={{childrenGap: 8}}
               styles={{
                   root: {
                       fontFamily: "Segoe UI Web (West European)",
                       width: "100%",
                       paddingLeft: 4,
                       paddingRight: 4,
                       color: labelStyles !== undefined && labelStyles !== null ? labelStyles.color : "unset",
                       backgroundColor: labelStyles !== undefined && labelStyles !== null ? labelStyles.backgroundColor : "unset",
                       alignItems: "center",
                   }
               }}
        >
            <Stack grow={1} styles={{
                root: {
                    textAlign: labelStyles !== undefined && labelStyles !== null ? labelStyles.textAlign : "unset",
                    fontStyle: labelStyles !== undefined && labelStyles !== null ? labelStyles.fontStyle : "unset",
                    textDecoration: labelStyles !== undefined && labelStyles !== null ? labelStyles.textDecoration : "unset",
                    fontWeight: labelStyles !== undefined && labelStyles !== null ? labelStyles.fontWeight : "normal",
                    fontSize: labelStyles !== undefined && labelStyles !== null ? labelStyles.fontSize : "14px"
                }
            }}>
                {props.label}
            </Stack>

            {(props.required || props.isRequired) &&
                <Stack styles={{root: {textAlign: "center"}}}>
                    <i className="ms-Icon ms-Icon--AsteriskSolid" title={t("label.required", {ns: "wizard"})}
                       aria-hidden="true" style={{
                        fontSize: "8px",
                        color: requiredColor(labelStyles)
                    }}></i>
                </Stack>
            }
            
            {l.TooltipUrl !== undefined && l.TooltipUrl !== null && l.TooltipUrl.trim().length > 0 &&
                <Stack>
                    <TooltipHost
                        styles={{root: {alignItems: "center", justifyContent: "center", display: "flex"}}}>
                        <Icon
                            onClick={() => {
                                window.open(l.TooltipUrl, "_blank");
                            }}
                            id={"icon" + props.id}
                            styles={{
                                root: {
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    color: labelStyles !== undefined && labelStyles !== null ? labelStyles.color : theme.palette.neutralPrimary
                                }
                            }}
                            iconName={"Info"}
                            title={l.TooltipUrl}
                        />
                    </TooltipHost>
                </Stack>
            }            

        </Stack>
        </>
    )
}


export const cleanHtml = (html: string) => {
    let div: HTMLDivElement = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
};
export const getLayoutFontColor = (fontColor: string) => {
    if (fontColor === null || fontColor === "" || fontColor === undefined ) {
        return "#000";
    } else {
        return fontColor;
    }
};

export const getLayoutBackgroundColor = (backgroundColor?: string) => {
    if (backgroundColor === null || backgroundColor === undefined || backgroundColor === "") {
        return emptyBackgroundColor.toString()
    } else {
        return backgroundColor
    }
}

export const getLayoutJustifyContent = (align: string) => {
    if (align === null || align === "") {
        return "left";
    } else {
        return align;
    }
};

export const getLayoutFontSize = (fontSize: number, type: InputType) => {
    
    if (fontSize === null || fontSize === 0) {
        switch (type) {
            case InputType.SectionHeader: {
                return 18;
            }
            case InputType.Paragraph: {
                return 14;
            }
            default:
                return 14;
        }
    } else {
        return fontSize;
    }
};

export const getFontWeight = (isBold: boolean) => {
    if (isBold === undefined || isBold === null || isBold === false) {
        return "normal";
    } else if (isBold) {
        return "bold";
    }

    return "normal";
};

export const getFontStyle = (isItalic: boolean) => {
    if (isItalic === undefined || isItalic === null || isItalic === false) {
        return "normal";
    } else if (isItalic) {
        return "italic";
    }

    return "normal";
};

export const getTextDecoration = (isUnderlined: boolean) => {
    if (isUnderlined === undefined || isUnderlined === null || isUnderlined === false) {
        return "none";
    } else if (isUnderlined) {
        return "underline";
    }

    return "none";
};
export const getValidations = (layout: ILayout, form: UseFormReturn<any, any>) => {
    if (layout.Type !== InputType.Paragraph && layout.Type !== InputType.SectionHeader && layout.Type !== InputType.LineBreak && layout.Type !== InputType.GridList) {
        let rules: RegisterOptions = {};
        const validaciones = FieldTypeValidations[layout.Type];
        validaciones.forEach((validationType: ValidationType) => {
            if ((validationType === ValidationType.Mask && layout.Type === InputType.Textbox && layout.Validations.Mask.length === 0) || (validationType === ValidationType.Regex && layout.Type === InputType.Textbox && layout.Validations.Regex.length === 0)) {
                return;
            }
            if (validationType === ValidationType.Required && layout.Validations.Required === false) {
                return;
            }

            var newRule = validations[validationType](layout);
            var newValidate = newRule.validate;
            if (newValidate !== undefined) {
                rules.validate = Object.assign({}, newValidate, rules.validate);
            }
            rules = Object.assign({}, newRule, rules);
        });
        form.register(layout.Id, rules);
    }
};

export const validations: EnumDictionary<ValidationType, (layout: ILayout) => RegisterOptions> = {
    [ValidationType.Required]: (layout: ILayout): RegisterOptions => {
        if (layout.Type === InputType.DropDownList) {
            return {
                validate: {
                    //requiredCustom: (value: any) => string | boolean | string[] | Promise<ValidateResult> | undefined
                    requiredCustom: (value: any) => {
                        if (value === undefined) {
                            return t("messages.errorRequired", {
                                ns: "wizard",
                                Field: layout.Label,
                            }).toString()
                        }
                        if (String(value.key).trim() === "") {
                            return t("messages.errorRequired", {
                                ns: "wizard",
                                Field: layout.Label,
                            }).toString()
                        }
                        return true;
                    },
                },
            };
        } else if (layout.Type === InputType.RichText) {
            return {
                validate: {
                    requiredCustom: (value: any) => {
                        if (
                            EditorValue.createFromString(value as string, "html")
                                .getEditorState()
                                .getCurrentContent()
                                .getPlainText().length === 0
                        ) {
                            return t("messages.errorRequired", {
                                ns: "wizard",
                                Field: layout.Label,
                            }).toString()
                        } else {
                            return true;
                        }
                    },
                },
            };
        } else {
            return {
                required: t("messages.errorRequired", {
                    ns: "wizard",
                    Field: layout.Label,
                }).toString(),
            };
        }
    },
    [ValidationType.MaxLength]: (layout: ILayout): RegisterOptions => {
        if (layout.Type === InputType.RichText) {
            return {
                validate: {
                    correctMaxLength: (value: any) => {
                        if (
                            EditorValue.createFromString(value as string, "html")
                                .getEditorState()
                                .getCurrentContent()
                                .getPlainText().length > layout.Validations.MaxLength
                        ) {
                            return t("messages.errorImageMaxlenght", {
                                ns: "wizard",
                                CurrentLenght: layout.Label,
                                MaximumLenght: layout.Validations.MaxLength!,
                            }).toString()
                        } else {
                            return true;
                        }
                    },
                },
            };
        } else {
            return {
                maxLength: {
                    value: layout.Validations.MaxLength!,
                    message: t("messages.errorImageMaxlenght", {
                        ns: "wizard",
                        CurrentLenght: layout.Label,
                        MaximumLenght: layout.Validations.MaxLength!,
                    }),
                },
            };
        }
    },
    [ValidationType.Mask]: (layout: ILayout): RegisterOptions => {
        return {
            validate: {
                notUnderscore: (value) => {
                    if (
                        (layout.Validations.Required === false &&
                            (layout.Validations.Mask.split("s")
                                .join("")
                                .split("9")
                                .join("")
                                .split("L")
                                .join("").length ===
                                value?.split("_").join("").length ||
                                layout.Validations.Mask.length ===
                                value?.split("_").join("").length)) ||
                        (layout.Validations.Required === true &&
                            layout.Validations.Mask.length ===
                            value?.split("_").join("").length)
                    ) {
                        return true;
                    } /*else {
                        return t("messages.successValid", {
                            ns: "wizard",
                            Field: layout.Label,
                        }).toString()
                    }*/
                },
            },
        };
    },
    [ValidationType.Regex]: (layout: ILayout): RegisterOptions => {
        return {
            pattern: {
                value: new RegExp(layout.Validations.Regex),
                message: layout.Validations.ErrorMessage,
            },
        };
    },
    [ValidationType.DateFormat]: (layout: ILayout): RegisterOptions => {
        return {
            validate: {
                validDateFormat: (value) => {
                    if (datePickerFormats.find((f) => f.key === layout.Validations.Regex) === undefined) {
                        return t("messages.errorDateFormat", {
                            ns: "wizard",
                        }).toString()
                    } else {
                        return true;
                    }
                },
            },
        };
    },
};
